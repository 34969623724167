import { useState } from 'react'
import axios from 'axios'
import { handleErrorCatching } from '../../handleErrorCatching'
import { isFalsyFromLocalStorage } from '../../isFalsyFromLocalStorage'





export function useScrapeClients(scrapeType, appState, options) {
  const {
    sortFn = null
  } = options

  const [scrapedClients, setScrapedClients] = useState([])
  const [isScrapingClients, setIsScrapingClients] = useState(false)


  async function scrapeClients() {
    const controller = new AbortController()
    try {
      setIsScrapingClients(true)
      const bool = true
      if (bool) {
        let payload = {
          username: isFalsyFromLocalStorage(appState.user?.requests?.EL?.username) ? process.env.REACT_APP_EL_USER : appState.user?.requests?.EL?.username,
          password: isFalsyFromLocalStorage(appState.user?.requests?.EL?.password) ? process.env.REACT_APP_EL_PASS : appState.user?.requests?.EL?.password,
          userId: isFalsyFromLocalStorage(appState.user?.requests?.EL?.userId) ? process.env.REACT_APP_EL_USERID : appState.user?.requests?.EL?.userId,
          scrapeType: scrapeType,
        }
        const response = await axios.post(`/python/clients`, {payload: payload, token: appState.user.token}, {signal: controller.signal})
        // const response = await axios.post(`/python/Clients?testing=true`, {payload: payload, token: appState.user.token}, {signal: controller.signal}) // TODO: getDocs or getDocsById  // const docs = await getDocsById("clients", "ELid", id, controller, true)
        console.log(`retrievededDocs (scrapeClients): `, response.data)

        // TODO (update state in some way)
        ///////////////////////////
        // if (Array.isArray(response.data) && response.data.length > 0) {
          //   setClients(response.data)
          // }
          
          // (( update db on BE ))
          // if (response.data?.length > 0) {
            // }
        if (response.data.success) {
          // window.location.reload()
          let fetchedDocs = response.data.docs
          if (sortFn) {
            fetchedDocs = response.data.docs.sort(sortFn)
          }
          setScrapedClients(fetchedDocs) // may need to watch 'scrapedClients' on parent component to update UI (e.g. useEffect, update docsFinal)
        }
      }
    } catch(e) {
      handleErrorCatching(e, "retrievedDocs, scrapeClients")
    } finally {
      setIsScrapingClients(false)
    }

    // ...not really sure if this is needed, can't really stop the request.  However, SHOULD stop setIsScrapingClients from being set (ACTUALLY, that's ok, because it's within this function?)
    return () => {
      controller.abort()
    }
  }

  // function handleScrapeClients() {
  //   setIsScrapingClients(true)
  //   scrapeClients()
  // }


  return { scrapeClients, scrapedClients, isScrapingClients }
}



