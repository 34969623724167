import React, { useState, useEffect, useContext, useRef, useCallback } from "react"
import { Box, Stack, TextField, Typography } from "@mui/material"
import { ModalGeneric } from "../../../components/utils/ModalGeneric"
import { TextFieldStyled } from "../../../components/utils/TextFieldStyled"
import { formatToCamelCase } from "../../../utils/formatToCamelCase"
import { formatToTanStackTableColumn } from "../../../utils/formatToTanStackTableColumn"
import { StateContext } from "../../../state-management/app/useContext/StateContext"
import axios from "axios"
import { updateDoc } from "../../../services/axios"
import { get, set } from "lodash"
import { onKeyDown } from "../../../utils/onKeyDown"
import { useDebounce } from "../../../utils/hooks/useDebounce"
import { isValidDate, isValidDateStr } from "../../../utils/isValidDate"


export function ModalPropertyValueUpdate({
  open = false,
  setOpen,

  doc = {}, // row.original
  property = "", // property to update

  setDocs = () => {},
}) {
  const callbackRef = useCallback(inputElement => { // https://blog.maisie.ink/react-ref-autofocus/
    if (inputElement) {
      setTimeout(() => {
        inputElement.focus();
        // inputElement.select();
        // inputRef.current?.focus();
        // console.log("focused");
      }, 1);
    }
  }, []);

  // const appState = useContext(StateContext)

  const [value, setValue] = useState(get(doc, property) || "")

  const [disabledSave, setDisabledSave] = useState(false)
  const [saveErrors, setSaveErrors] = useState([])
  
  const propertyWithoutCustom = property.includes("custom.") ? property.replace("custom.", "") : property
  const cashDateColumn = propertyWithoutCustom == "cashDate"
  
  useDebounce(() => {
    if (cashDateColumn) {
      if (value === "") {
        setDisabledSave(false)
        setSaveErrors([])
      }
      else if (value.split("/").length < 3 || value.split("/").at(2).length < 2 || value.split("/").at(2).length == 3) {
        setDisabledSave(true)
        setSaveErrors([])
      }

      else if (!isValidDateStr(value, 4)) {
        setDisabledSave(true)
        // add error
        const index = saveErrors.findIndex(error => error === "Invalid date: must be YYYY format.")
        if (index > -1) {
          return
        }
        setSaveErrors(prev => [...prev, "Invalid date: must be YYYY format."])

      }
      else if (isValidDateStr(value, 4)) {
        setDisabledSave(false)
        // remove error
        setSaveErrors([])
        // const index = saveErrors.findIndex(error => error === "Invalid date: must be YYYY format.")
        // if (index > -1) {
        //   let updatedErrors = [...saveErrors].splice(index, 1)
        //   setSaveErrors(updatedErrors)
        // }
      }
      else {
        setDisabledSave(true)
      }
    }
  }, 100, [value])
// useDebounce(() => alert(value), 1000, [value])

  async function handleSave() {
    // clean up value
    let cleanedValue = value
    if (typeof cleanedValue === "string") {
      cleanedValue = cleanedValue.trim()
    }

    // set update properties (+ _id to find doc)
    doc = {
      _id: doc._id,
      [property]: cleanedValue
    }
    // set(doc, property, cleanedValue) <-- THIS IS INCORRECT (IT WILL OVERRIDE/DELETE ANY OTHER VALUES WITHIN THE 'NEST PROPERTY' THAT ARE ALREADY ON THE DBDOC!)
  
    // update doc on db
    const controller = new AbortController()
    const updatedDoc = await updateDoc(doc, "invoices", controller, true)
    // console.log(`updated doc: `, updatedDoc)

    // side effects  
    setDocs(prev => prev.map(prevDoc => prevDoc._id == updatedDoc._id ? updatedDoc : prevDoc))
    setOpen(false)
  
    return () => controller.abort()
  }



  // async function handleSave() {
  //   // let camelCase = formatToCamelCase(customColumn)
  //   // let formattedCustomColumn = formatToTanStackTableColumn(camelCase)
    
  //   const controller = new AbortController()
  //   async function updatePropertyValue() {
  //     try {
  //       // let customPropertyName = camelCase
  //       // let response = await axios.post("/invoices/custom/property", {customPropertyName: camelCase, token: appState.user.token}, {signal: controller.signal})
  //       console.log("response (set custom property)", response)
  //       if (Boolean(response) == true) {
  //         console.log("true!")
  //       }
  //     } catch(e) {
  //       console.log("There was a problem or the request was cancelled. (set custom property)", e)
  //     } finally {
  //       setOpen(false)
  //     }
  //   }
  //   await updatePropertyValue()

  //   console.log("done with function")
  //   // return () => {
  //   //   controller.abort()
  //   // }
  // }


  // AUTOFOCUS (REF)
  // useEffect(() => {
  //   if (open && textFieldRef.current !== null) {
  //     textFieldRef.current.focus()
  //   }
  // }, [open])


  

  return (
    <>
      <ModalGeneric open={open} setOpen={setOpen} handleSave={() => handleSave()}
        disabledSave={disabledSave}
        saveErrors={saveErrors}
      >

        <>
          <TextFieldStyled
            inputRef={callbackRef}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => onKeyDown(e, handleSave)}
            label={`Update ${property}`}
            fullWidth={true}
          />

          {/* <Typography variant="body2">
            {formatToCamelCase(value)}
          </Typography> */}
        </>

      </ModalGeneric>
    </>
  )
}

