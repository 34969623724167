import { ClearRounded, DeleteRounded, EditRounded } from "@mui/icons-material"
import { Stack, Typography } from "@mui/material"
import axios from "axios"
import { get, set } from "lodash"
import { useState } from "react"
import { ButtonMoreInfo } from "../components/utils/ButtonMoreInfo"
import { IconButtonStyled } from "../components/utils/IconButtonStyled"
import { ModalPropertyValueUpdate } from "../pages/clients/components/ModalProperyValueUpdate"
import { isValidDate } from "./isValidDate"
import { updateDoc } from "../services/axios"
import { filterDates } from "./filterDates"
import { TypographyError } from "../components/utils/TypographyError"



async function handleDelete(doc, property, setDocs) {
  const response = window.confirm("Are you sure you want to delete this value?")
  console.log("response", response)
  if (response) {
    // set update properties (+ _id to find doc)
    doc = {
      _id: doc._id,
    }
    set(doc, property, "")
  
    // update doc on db
    const controller = new AbortController()
    const updatedDoc = await updateDoc(doc, "invoices", controller, true)
    console.log(`updated doc: `, updatedDoc)

    // side effects  
    setDocs(prev => prev.map(prevDoc => prevDoc._id == updatedDoc._id ? updatedDoc : prevDoc))
  
    return () => controller.abort()
  }
}





const UpdateValueBtn = ({row, property, setDocsOriginal}) => {
  const [open, setOpen] = useState(false)

  return (
      <>
        <IconButtonStyled
          onClick={() => setOpen(true)}
        >
          <EditRounded sx={{fontSize: "1.2rem"}} />
        </IconButtonStyled>

        <ModalPropertyValueUpdate doc={row.original} property={property} open={open} setOpen={setOpen} setDocs={setDocsOriginal} />
      </>
  )
}


const DeleteValueBtn = ({row, property, setDocsOriginal}) => {
  return (
    <IconButtonStyled color="error"
      onClick={() => handleDelete(row.original, property, setDocsOriginal)}
    >
      <ClearRounded />
    </IconButtonStyled>
  )
}


const ExistingValueComponent = ({row, property, setDocsOriginal}) => {
  const [open, setOpen] = useState(false)

  const value = get(row.original, property)

  return (
    <>
      <Stack direction="row" gap={0.5} alignItems="center">
        <Typography>
          {/* {get(row.original, property)} */}
          {value}
        </Typography>

        {((property == "custom.cashDate" || property == "cashDate") && value != "")
          ? value?.split("/").at(-1).length < 4
            ? <TypographyError sx={{opacity: 0.8}}>*REFORMAT*</TypographyError>
            : null
          : null
        } 
        
        <Stack direction="row">
          <UpdateValueBtn row={row} property={property} setDocsOriginal={setDocsOriginal} />
          <DeleteValueBtn row={row} property={property} setDocsOriginal={setDocsOriginal} />
        </Stack>

      </Stack>

      <ModalPropertyValueUpdate doc={row.original} property={property} open={open} setOpen={setOpen} setDocs={setDocsOriginal} />
    </>
  )
}




async function handleDeleteColumn(property) {
  const response = window.confirm("Are you sure you want to delete this column?  This will affect all invoices.")
  console.log("response", response)
  if (response) {
    const controller = new AbortController()

    console.log("property to delete", property)
    let response = await axios.post("/invoices/custom/property?delete=true", {customPropertyName: property}, {signal: controller.signal})
    console.log("response (delete custom property)", response)

    if (Boolean(response) == true) {
      window.location.reload()
      // window.alert("Custom column deleted. Please refresh the page to allow for the change to properly take place.")
    }

    return () => controller.abort()
  }
}






const DeleteColumnBtn = ({property}) => {
  return (
    <IconButtonStyled color="error"
      onClick={() => handleDeleteColumn(property)}
    >
      <ClearRounded sx={{fontSize: "1.2rem"}} />
    </IconButtonStyled>
  )
}


// function displayValueDateOrAlphanumeric(value="") {
//   if (new Date(value)) {
//     return new Date(value)
//   }
//   else {
//     return value
//   }
// }


function sortingFn(rowA, rowB, property, desc) {
  let valueA = Boolean(get(rowA, property)) ? get(rowA, property) : null
  let valueB = Boolean(get(rowB, property)) ? get(rowB, property) : null

  /**
   * These first 3 conditions keep our null values at the bottom. (NO IT DOESN'T)
   */
  if (valueA === null && valueB !== null) {
    return desc ? -1 : 1
  }

  if (valueA !== null && valueB === null) {
    return desc ? 1 : -1
  }

  if (valueA === null && valueB === null) {
    return 0
  }



  if ((valueA?.includes("-") || valueA?.includes("/")) && (valueB?.includes("-") || valueB?.includes("/")) && isValidDate(new Date(valueA)) && isValidDate(new Date(valueB))) {
    valueA = new Date(valueA)
    valueB = new Date(valueB)
  }
  else if (Number(valueA) && Number(valueB)) {
    valueA = Number(valueA)
    valueB = Number(valueB)
  }

  try {
    if (valueA > valueB) return 1
    if (valueB > valueA) return -1
    return 0
  }
  catch {
    return 0
  }
}

function headerCell(property) {
  const propertyName = property.includes("custom.") ? property.replace("custom.", "") : property
  const isPermanentColumn = propertyName === "cashDate" || propertyName === "note"

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography
        sx={{
          fontWeight: 600, 
          color: "primary.main"
        }}
      >
        {propertyName}
      </Typography>

      <Stack direction="row" alignItems="center">
        <ButtonMoreInfo iconOnly columnHeader label="Type asterisk (*) to filter out rows with empty values" />
        {!isPermanentColumn && <DeleteColumnBtn property={property} />}
      </Stack>

    </Stack>
  )
}

function muiTableHeaderCellProps() {
  return ({
      sx: {
        "& .Mui-TableHeadCell-Content-Wrapper": {
          overflow: "visible",
        }
        // backgroundColor: selectedBackgroundColor,
        // borderLeft: "2px solid lightgray"
      }
  })
}


export function formatToTanStackTableColumn(property="", setDocsOriginal = ()=>{}, rest = []) {  // property = can be nested ("this.nested.property")  

  const propertyWithoutCustom = property.includes("custom.") ? property.replace("custom.", "") : property
  const isCashDateColumn = propertyWithoutCustom === "cashDate"

  return (
    {
      // accessorFn: ({row}) => displayValueDateOrAlphanumeric(get(row, property)),
      accessorKey: property,

      muiTableHeadCellProps: muiTableHeaderCellProps(),
      // muiTableBodyCellProps: muiTableBodyCellProps(),

      // header: property.includes("custom.") ? property.replace("custom.", "") : property,
      header: headerCell(property), // Mui-TableHeadCell-Content-Wrapper  (overflow: "visible")


      sortingFn: (rowA, rowB, columnId, desc) => sortingFn(rowA.original, rowB.original, property, desc),  // https://stackoverflow.com/questions/63927644/how-does-one-supply-a-custom-sort-function-for-react-table-7


      // filterVariant: 'multi-select',
      // filterSelectOptions: [
      //   {text: "Active", value: "A"},
      //   {text: "Drilling", value: "DRL"},
      //   {text: "Confidential", value: "Confidential"},
      //   {text: "Permitted", value: ["LOC", "LOCR", "NC", "NCW"]},
      //   {text: "Inactive", value: ["IA", "IAW"]}, 
      //   {text: "Temporarily Abandoned", value: ["PNS", "TA", "TAO", "TASC", "TATD", "TAI"]}, 
      //   {text: "Abandoned", value: ["AB", "DRY", "EXP", "PA", "PNC"]}, 
      // ],
      filterVariant: isCashDateColumn ? "range" : "fuzzy",
      filterFn: isCashDateColumn ? filterDates : (row, id, filterValue) => {
        // console.log("filterValue: ", filterValue)
        // console.log("row value & filterFn bool: ", row.getValue(id), filterValue.length > 0 ? filterValue.flat().includes(row.getValue(id)) : true)
        return filterValue.length > 0
                  ? filterValue.trim() == "*"
                    ? row.getValue(id).length > 0
                  : row.getValue(id).includes(filterValue)
                : true
      },
      muiTableHeadCellFilterTextFieldProps: ({ table, column, rangeFilterIndex}) => ({
        placeholder: isCashDateColumn 
          ? rangeFilterIndex == 0 ? `From` : `To`
          : `Filter by ${propertyWithoutCustom}`,
      }),
      muiTableHeadCellProps: ({ table, column }) => ({
        sx: {
          "& .MuiCollapse-root .MuiBox-root": {
            display: isCashDateColumn &&  "flex",
            flexDirection: isCashDateColumn && "column",
            gap: isCashDateColumn && "0.5rem",
          }
        }
      }),


      Cell: ({ row }) => {
        if (get(row.original, property) && get(row.original, property) != "") {
          return <ExistingValueComponent row={row} property={property} setDocsOriginal={setDocsOriginal} />
        }
        else {
          return <UpdateValueBtn row={row} property={property} setDocsOriginal={setDocsOriginal} />
        }
      }
    }
  )
}

