import { useContext, useState } from 'react'
import axios from 'axios'
import { handleErrorCatching } from '../../handleErrorCatching'
import { isFalsyFromLocalStorage } from '../../isFalsyFromLocalStorage'
import { DispatchContext } from '../../../state-management/app/useContext/DispatchContext'
import { SCRAPETYPE } from '../../constants'





export function useScrapeInvoices(scrapeType, clientObj={}, appState, options={}) {
  const {
    initialFetch = false,
  } = options

  const appDispatch = useContext(DispatchContext)

  const [scrapedInvoices, setScrapedInvoices] = useState([])
  const [isScrapingInvoices, setIsScrapingInvoices] = useState(false)
  const [initialFetchSuccess, setInitialFetchSuccess] = useState(false)


  async function scrapeInvoices(arg={}) {
    const {
      client = {},
      invoiceId = "",
      // invoiceUrl = "",
      table = null,
      requestType = "",
    } = arg

    const controller = new AbortController()
    try {
      setIsScrapingInvoices(true)

      let bool = false
      if (scrapeType == SCRAPETYPE.getInvoicesAndStatementsOfClients) {
        bool = window.confirm("Are you sure you want to pull this invoice? This may take a few minutes for older or larger invoices.")
      } else if (scrapeType == SCRAPETYPE.getInvoicesOfClients) {
        bool = window.confirm("Are you sure you want to look for new invoices? This may take a couple minutes.")
      }

      if (bool) {
        let clientId = clientObj.ELid || client.ELid

        let response

        // if (scrapeType == SCRAPETYPE.getInvoicesAndStatementsOfClientsWithInvoiceUrls) {
        //   console.log("got here!", SCRAPETYPE.getInvoicesAndStatementsOfClientsWithInvoiceUrls)
        //   let payload = {
        //     username: isFalsyFromLocalStorage(appState.user?.requests?.EL?.username) ? process.env.REACT_APP_EL_USER : appState.user?.requests?.EL?.username,
        //     password: isFalsyFromLocalStorage(appState.user?.requests?.EL?.password) ? process.env.REACT_APP_EL_PASS : appState.user?.requests?.EL?.password,
        //     userId: isFalsyFromLocalStorage(appState.user?.requests?.EL?.userId) ? process.env.REACT_APP_EL_USERID : appState.user?.requests?.EL?.userId,
        //     scrapeType: scrapeType,
        //     reqClientIds: [clientId],
        //     reqClientsAndInvoices: [{ ELid: clientId, invoiceUrls: [invoiceUrl] }]
        //   }
        //   console.log("payload: ", payload)
        //   response = await axios.post(`/python/invoices`, {payload: payload, token: appState.user.token}, {signal: controller.signal})
        // }
        // else {
        let payload = {
          username: isFalsyFromLocalStorage(appState.user?.requests?.EL?.username) ? process.env.REACT_APP_EL_USER : appState.user?.requests?.EL?.username,
          password: isFalsyFromLocalStorage(appState.user?.requests?.EL?.password) ? process.env.REACT_APP_EL_PASS : appState.user?.requests?.EL?.password,
          userId: isFalsyFromLocalStorage(appState.user?.requests?.EL?.userId) ? process.env.REACT_APP_EL_USERID : appState.user?.requests?.EL?.userId,
          scrapeType: scrapeType,
          reqClientIds: [clientId],
          reqClientsAndInvoices: scrapeType == SCRAPETYPE.getInvoicesAndStatementsOfClients ? [{ ELid: clientId, invoiceIds: [invoiceId] }] : []
        }
        console.log("payload: ", payload)

        if (scrapeType == SCRAPETYPE.getInvoicesAndStatementsOfClients) {
          console.log("got here!", SCRAPETYPE.getInvoicesAndStatementsOfClients)
          response = await axios.post(`/python/invoices`, {payload: payload, token: appState.user.token}, {signal: controller.signal})
        }
        else {
          console.log("got here!", "different scrape type!")
          if (requestType == "initialFetch") {
            console.log("requestType == initialFetch")
            response = await axios.post(`/python/invoices`, {payload: payload, token: appState.user.token}, {signal: controller.signal})
          } else {
            console.log("requestType != initialFetch")
            response = await axios.post(`/python/invoices?newInvoicesOnly=true`, {payload: payload, token: appState.user.token}, {signal: controller.signal})
          }
        }
        // }
        // const response = await axios.post(`/python/invoices?testing=true`, {payload: payload, token: appState.user.token}, {signal: controller.signal}) // TODO: getDocs or getDocsById  // const docs = await getDocsById("clients", "ELid", id, controller, true)
        console.log(`retrievededDocs (scrapeInvoices - ${clientId}): `, response.data)

        // TODO (update state in some way)
        ///////////////////////////
        // if (Array.isArray(response.data) && response.data.length > 0) {
        //   setInvoices(response.data)
        // }

        // (( update db on BE ))
        // if (response.data?.length > 0) {
        // }
        if (response.data.success) {
          // window.location.reload()
          setScrapedInvoices(response.data.docs) // may need to watch 'scrapedInvoices' on parent component to update UI (e.g. useEffect, update docsFinal)
          if (table) {
            table.resetRowSelection()
          }

          const scrapedDocsLength = response.data.docs.length
          if (scrapedDocsLength > 0) {
            appDispatch({type: "flashMessage", value: {message: `Successfully pulled ${scrapedDocsLength} invoice${scrapedDocsLength > 1 ? "s" : ""}.`, severity: "success"} })
          }
          else {
            appDispatch({type: "flashMessage", value: {message: `Error pulling invoice(s).`, severity: "error"} })
          }
          
          if (initialFetch) {
            setInitialFetchSuccess(true)
          }
        }
      }
    } catch(e) {
      handleErrorCatching(e, "retrievedDocs, scrapeInvoices")
    } finally {
      setIsScrapingInvoices(false)
    }

    // ...not really sure if this is needed, can't really stop the request.  However, SHOULD stop setIsScrapingInvoices from being set (ACTUALLY, that's ok, because it's within this function?)
    return () => {
      controller.abort()
    }
  }

  // function handleScrapeInvoices() {
  //   setIsScrapingInvoices(true)
  //   scrapeInvoices()
  // }


  return { scrapeInvoices, scrapedInvoices, isScrapingInvoices, initialFetchSuccess, setInitialFetchSuccess }
}




// export function useScrapeInvoices(scrapeType, clientObj, appState) {
//   const [isScrapingInvoices, setIsScrapingInvoices] = useState(false)
//   const [scrapeInvoicesRequestCount, setScrapeInvoicesRequestCount] = useState(0)


//   async function scrapeInvoices() {

//   }

//   useEffect(() => {
//     if (scrapeInvoicesRequestCount > 0) {
//       const controller = new AbortController()
//       async function getDocs() {
//         try {
//           let bool = window.confirm("Are you sure you want to look for new invoices? This may take up to 1 minute.")
//           if (bool) {
//             let clientId = clientObj.ELid  // || "71484"

//             let payload = {
//               // "undefined", "null" = d/t messing with localStorage (they are strings, not null/undefined datatypes/"objects")
//               username: isFalsyFromLocalStorage(appState.user?.requests?.EL?.username) ? process.env.REACT_APP_EL_USER : appState.user?.requests?.EL?.username,
//               password: isFalsyFromLocalStorage(appState.user?.requests?.EL?.password) ? process.env.REACT_APP_EL_PASS : appState.user?.requests?.EL?.password,
//               userId: isFalsyFromLocalStorage(appState.user?.requests?.EL?.userId) ? process.env.REACT_APP_EL_USERID : appState.user?.requests?.EL?.userId,
//               scrapeType: scrapeType,
//               reqClientIds: [clientId],
//               reqClientsAndInvoices: []  // [ { ELid: "", invoiceIds: ["172385", "12798539"] } ] 
//             }
//             const response = await axios.post(`/python/invoices?newInvoicesOnly=true`, {payload: payload, token: appState.user.token}, {signal: controller.signal})  // TODO: getDocs or getDocsById  // const docs = await getDocsById("clients", "ELid", id, controller, true)
//             // const response = await axios.post(`/python/invoices?testing=true`, {payload: payload, token: appState.user.token}, {signal: controller.signal}) // TODO: getDocs or getDocsById  // const docs = await getDocsById("clients", "ELid", id, controller, true)
//             console.log(`retrievededDocs (scrapeInvoices - ${clientId}): `, response.data)

//             // TODO (update state in some way)
//             ///////////////////////////
//             // if (Array.isArray(response.data) && response.data.length > 0) {
//             //   setInvoices(response.data)
//             // }

//             // (( update db on BE ))
//             // if (response.data?.length > 0) {
//             // }
//             if (response.data.success) {
//               window.location.reload()
//             }
//           }
//         } catch(e) {
//           handleErrorCatching(e, "retrievedDocs, scrapeInvoices")
//         } finally {
//           setIsScrapingInvoices(false)
//         }
//       }
//       getDocs()
//       return () => { controller.abort() }
//     }
//   }, [scrapeInvoicesRequestCount])


//   function handleScrapeInvoices() {
//     setIsScrapingInvoices(true)
//     setScrapeInvoicesRequestCount(prev => prev + 1)
//   }


//   return { handleScrapeInvoices, isScrapingInvoices }
// }


