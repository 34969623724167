import React, { useState, useEffect } from "react"
import { Logo } from "./Logo"
import { keyframes } from "@mui/system"
import { Box, Stack } from "@mui/material"
import { logoIcon } from "../../utils/constants"
import { LoadingModal } from "./LoadingModal"
import { LogoText } from "./LogoText"

export function LoadingScreen({
  
}) {

  // const Logo = ""

  const pulse = keyframes`
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  `
  const animation = `${pulse} 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite alternate both`
  
  const logoUrl = logoIcon
  

  return (
    <>
      <Logo width={"100px"} label={false} animate={true} sx={{height: 1, width: 1}} />

      <LogoText width={"100px"} label={false} animate={false} sx={{height: 1, width: 1}} />

      <LoadingModal open={true} />
      {/* LOGO */}
      {/* <Stack direction="row" alignItems="center" justifyContent="center" gap={1} sx={{height: 1, width: 1}} >
        <Box component="img" sx={{width: "100px", animation: animation}}
            src={logoUrl}
          />
      </Stack> */}


    </>
  )
}

