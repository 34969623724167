import "./App.css";
import { useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import axios from "axios";
import { useImmerReducer } from "use-immer";

import { nanoid } from "nanoid";

import { APP_ABBREV } from "./utils/constants";

import { SiteProvidersWrapper } from "./components/layout/SiteProvidersWrapper";
import SiteContainer from "./components/layout/SiteContainer";

import NotFound from "./pages/_error/NotFound";

import SignIn from "./pages/signin/SignIn";
import Register from "./pages/register/Register";
import ForgotPassword from "./pages/forgotpassword/ForgotPassword";

import Users from "./pages/users/Users";
import User from "./pages/users/User";
import HomeLoggedIn from "./pages/home/HomeLoggedIn";
import HomeLoggedOut from "./pages/home/HomeLoggedOut";
import { StateContext } from "./state-management/app/useContext/StateContext";
import { DispatchContext } from "./state-management/app/useContext/DispatchContext";
import { Toast } from "./components/utils/Toast";
import { handleErrorCatching } from "./utils/handleErrorCatching";
import Clients from "./pages/clients/Clients";
import Client from "./pages/clients/Client";
import Invoice from "./pages/clients/Invoice";
import Statement from "./pages/clients/Statement";
import Admin from "./pages/admin/Admin";
import Profile from "./pages/profile/Profile";
import { LoadingModal } from "./components/utils/LoadingModal";
import { AnimatePresence } from "framer-motion";
import { LoadingScreen } from "./components/utils/LoadingScreen";
import { Testing } from "./pages/_testing/Testing";

axios.defaults.baseURL =
  process.env.REACT_APP_BACKENDURL || "http://localhost:4000";

function App() {
  console.log("APP RENDERED");

  // const location = useLocation()
  // console.log("BACKENDURL", process.env.REACT_APP_BACKENDURL)

  const appDispatch = useContext(DispatchContext);

  // INITIAL STATE
  const initialState = {
    loggedIn: Boolean(localStorage.getItem(`${APP_ABBREV}Token`)),
    theme: localStorage.getItem(`${APP_ABBREV}Theme`) || "light", // theme: localStorage.getItem(`${APP_ABBREV}Theme`) || window?.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light",
    flashMessages: [],
    loadingModal: false,
    user: {
      token: localStorage.getItem(`${APP_ABBREV}Token`),

      _id: localStorage.getItem(`${APP_ABBREV}Id`),
      username: localStorage.getItem(`${APP_ABBREV}Username`),
      first: localStorage.getItem(`${APP_ABBREV}First`),
      last: localStorage.getItem(`${APP_ABBREV}Last`),
      avatar: localStorage.getItem(`${APP_ABBREV}Avatar`),

      requests: {
        EL: {
          username: localStorage.getItem(`${APP_ABBREV}ELUsername`),
          password: localStorage.getItem(`${APP_ABBREV}ELPass`),
          userId: localStorage.getItem(`${APP_ABBREV}ELUserId`),
        },
      },
    },

    columnVisibility: {
      tableInvoices: localStorage.getItem(
        `${APP_ABBREV}ColumnVisibilityTableInvoices`
      ),
      tableStatements: localStorage.getItem(
        `${APP_ABBREV}ColumnVisibilityTableStatements`
      ),
    },
  };
  function ourReducer(draft, action) {
    switch (action.type) {
      case "login":
        draft.loggedIn = true;
        draft.user = action.data;
        // TODO: draft.user = action.data.user
        // TODO: draft.requests = action.data.requests
        return;
      case "logout":
        draft.loggedIn = false;
        return;
      case "theme":
        draft.theme = draft.theme === "light" ? "dark" : "light";
        localStorage.setItem(`${APP_ABBREV}Theme`, draft.theme);
        return;
      case "clearFlashMessages":
        draft.flashMessages = [];
        return;
      case "flashMessage":
        draft.flashMessages.push(action.value);
        return;

      case "updateColumnVisibilityTableInvoices":
        draft.columnVisibility.tableInvoices = action.value;
        localStorage.setItem(
          `${APP_ABBREV}ColumnVisibilityTableInvoices`,
          action.value
        );
        return;
      case "updateColumnVisibilityTableStatements":
        draft.columnVisibility.tableStatements = action.value;
        localStorage.setItem(
          `${APP_ABBREV}ColumnVisibilityTableStatements`,
          action.value
        );
        return;

      // case "toggleLoadingModal":
      //   draft.loadingModal = !state.loadingModal
      //   return
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  // PERSIST DATA
  useEffect(() => {
    if (state.loggedIn) {
      localStorage.setItem(`${APP_ABBREV}Token`, state.user.token);
      localStorage.setItem(`${APP_ABBREV}Id`, state.user._id);
      localStorage.setItem(`${APP_ABBREV}Username`, state.user.username);
      localStorage.setItem(`${APP_ABBREV}First`, state.user.first);
      localStorage.setItem(`${APP_ABBREV}Last`, state.user.last);
      localStorage.setItem(`${APP_ABBREV}Avatar`, state.user.avatar);

      localStorage.setItem(`${APP_ABBREV}Theme`, state.theme);

      localStorage.setItem(
        `${APP_ABBREV}ELUsername`,
        state.user.requests?.EL?.username
      );
      localStorage.setItem(
        `${APP_ABBREV}ELPass`,
        state.user.requests?.EL?.password
      );
      localStorage.setItem(
        `${APP_ABBREV}ELUserId`,
        state.user.requests?.EL?.userId
      );

      localStorage.setItem(
        `${APP_ABBREV}ColumnVisibilityTableInvoices`,
        state.columnVisibility.tableInvoices || ""
      );
      localStorage.setItem(
        `${APP_ABBREV}ColumnVisibilityTableStatements`,
        state.columnVisibility.tableStatements || ""
      );
    } else {
      localStorage.removeItem(`${APP_ABBREV}Token`);
      localStorage.removeItem(`${APP_ABBREV}Id`);
      localStorage.removeItem(`${APP_ABBREV}Username`);
      localStorage.removeItem(`${APP_ABBREV}First`);
      localStorage.removeItem(`${APP_ABBREV}Last`);
      localStorage.removeItem(`${APP_ABBREV}Avatar`);

      // localStorage.removeItem(`${APP_ABBREV}Theme`)

      localStorage.removeItem(`${APP_ABBREV}ELUsername`);
      localStorage.removeItem(`${APP_ABBREV}ELPass`);
      localStorage.removeItem(`${APP_ABBREV}ELUserId`);

      localStorage.removeItem(`${APP_ABBREV}ColumnVisibilityTableInvoices`);
      localStorage.removeItem(`${APP_ABBREV}ColumnVisibilityTableStatements`);
    }
  }, [state.loggedIn]);

  // PREVENT FLASH MESSAGES FROM PERSISTING
  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: "clearFlashMessages" });
    }, 10000);
  }, [state.flashMessages]);

  // NONEXPIRED TOKEN CHECK (check if token has expired or not (on first render)
  useEffect(() => {
    if (state.loggedIn) {
      const controller = new AbortController();
      async function checkToken() {
        try {
          const response = await axios.post(
            "/checkToken",
            { token: state.user.token },
            { signal: controller.signal }
          );
          // console.log("isStillLoggedIn", response.data)
          let isStillLoggedIn = response.data; // bool
          if (isStillLoggedIn) {
            // console.log("Successful token check!")
            return;
          } else {
            dispatch({ type: "logout" });
            dispatch({
              type: "flashMessage",
              value: "Your session has expired. Please log in again.",
            });
          }
        } catch (e) {
          handleErrorCatching(e, "checkToken", appDispatch);
        }
      }
      checkToken();
      return () => {
        controller.abort();
      };
    }
  }, []);

  return (
    <SiteProvidersWrapper state={state} dispatch={dispatch}>
      {state.flashMessages.length > 0 &&
        state.flashMessages.map((message) => {
          if (typeof message === "string") {
            return <Toast key={nanoid()} message={message} />;
          } else {
            return (
              <Toast
                key={nanoid()}
                message={message.message}
                severity={message.severity}
              />
            );
          }
        })}
      {/* <LoadingModal open={} setOpen={} /> */}

      <BrowserRouter>
        <SiteContainer>
          {/* <AnimatePresence exitBeforeEnter> */}
          <AnimatePresence mode="wait">
            {/* <Routes location={location} key={location.pathname}> */}
            <Routes>
              <Route
                path="/*"
                element={state.loggedIn ? <HomeLoggedIn /> : <HomeLoggedOut />}
              />
              <Route
                path="/signin"
                element={state.loggedIn ? <HomeLoggedIn /> : <SignIn />}
              />
              <Route
                path="/register"
                element={state.loggedIn ? <HomeLoggedIn /> : <Register />}
              />
              <Route
                path="/forgotpassword"
                element={state.loggedIn ? <HomeLoggedIn /> : <ForgotPassword />}
              />
              <Route
                path="/profile"
                element={!state.loggedIn ? <HomeLoggedOut /> : <Profile />}
              />
              <Route
                path="/clients/*"
                element={!state.loggedIn ? <HomeLoggedOut /> : <Clients />}
              />
              <Route
                path="/clients/:id/*"
                element={!state.loggedIn ? <HomeLoggedOut /> : <Client />}
              />
              <Route
                path="/clients/:id/invoices/:invoiceId/*"
                element={!state.loggedIn ? <HomeLoggedOut /> : <Invoice />}
              />
              <Route
                path="/clients/:id/invoices/:invoiceId/:statementId/*"
                element={!state.loggedIn ? <HomeLoggedOut /> : <Statement />}
              />
              <Route path="/loading" element={<LoadingScreen />} />
              {/* TODO: REMOVE */}
              <Route
                path="/admin/*"
                element={
                  state.user.username == "aaronmotacek" ? (
                    <Admin />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/testing/*"
                element={
                  state.user.username == "aaronmotacek" ? (
                    <Testing />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route path="/users" element={<Users />} />
              <Route path="/users/:id" element={<User />} />
              <Route path="*" element={<NotFound />} /> {/* 404 */}
            </Routes>
          </AnimatePresence>
        </SiteContainer>
      </BrowserRouter>
    </SiteProvidersWrapper>
  );
}

export default App;
