export const APP_NAME = "CCG Web App"
export const APP_ABBREV = "CCG"


// https://bareynol.github.io/mui-theme-creator/
export const COLORS = {
  PRIMARY: {
    LIGHT: {
      // main: "#FE8032", // 60% HSL (actual logo color)
      MAIN: "#fe6201", // 50% HSL
      LIGHT: "#FE8133",
      DARK: "#B14400",
      CONTRAST_TEXT: "#fff"
    },
    DARK: {
      MAIN: "#b95705", // 50% HSL
      // LIGHT: "rgb(179, 111, 55)",
      // DARK: "rgb(112, 53, 4)",
      CONTRAST_TEXT: "#fff"  
    }
    // DARK: {
    //   MAIN: "#c95800", // 50% HSL
    //   LIGHT: "#D37933",
    //   DARK: "#B14400",
    //   CONTRAST_TEXT: "#fff"  
    // }
  },
  SECONDARY: "",

  MAIN: "#1976d2", // original: "#1976d2"
  PINK: "#ffc4ef",
  BLUE: "#c4ddff"
}


export const SCRAPETYPE = {
  getClientsOfUser: "getClientsOfUser",
  getInvoicesOfClients: "getInvoicesOfClients",
  getInvoicesAndStatementsOfClients: "getInvoicesAndStatementsOfClients",
  getInvoicesAndStatementsOfClientsWithInvoiceUrls: "getInvoicesAndStatementsOfClientsWithInvoiceUrls",
  getStatementsOfInvoices: "getStatementsOfInvoices",
}

export const SCRAPETYPE_PAYLOAD = {
  username: "",
  password: "",
  userId: "",
  reqClientIds: [],
  reqClientsAndInvoices: []
}

export const logoIconDark = "https://res.cloudinary.com/dxnehm70c/image/upload/v1679893995/CCG_logo_dark_yms75r.svg"
export const logoTextDark = "https://res.cloudinary.com/dxnehm70c/image/upload/v1679893995/CCG_logo_dark_text_jwhshf.svg"
export const logoIcon = "https://res.cloudinary.com/dxnehm70c/image/upload/v1679111946/CCG_logo_icon_lcv5gc.svg"
export const logoText = "https://res.cloudinary.com/dxnehm70c/image/upload/v1679111946/CCG_logo_text_vso5qp.svg"
export const logoFull = "https://res.cloudinary.com/dxnehm70c/image/upload/v1679111946/CCG_logo_main_ugpl7x.svg"
export const logoTextWhite = "https://res.cloudinary.com/dxnehm70c/image/upload/v1679111946/CCG_logo_text_white_r3dkiz.svg"
export const logoIconWhite = "https://res.cloudinary.com/dxnehm70c/image/upload/v1679962061/CCG_logo_white_l24p0d.svg"