import React, { useState, useEffect, useMemo, useContext } from "react";

import MaterialReactTable, {
  MRT_ShowHideColumnsButton,
  MRT_FullScreenToggleButton,
  MRT_ColumnDef,
  MRT_Localization,
  Virtualizer,
  MRT_RowSelectionState,
} from "material-react-table";
import { getFacetedUniqueValues } from "@tanstack/react-table";

import {
  Box,
  Button,
  ListItemIcon,
  Stack,
  Typography,
  IconButton,
  MenuItem,
  ButtonBase,
  Modal,
  Link,
  ButtonGroup,
  Tooltip,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import {
  AccountCircle,
  AddCircleOutlineTwoTone,
  AddCircleTwoTone,
  AddTwoTone,
  AutorenewRounded,
  CalendarViewMonthRounded,
  CheckBoxOutlineBlankRounded,
  Crop32Rounded,
  Edit,
  FileDownload,
  FileDownloadRounded,
  Print,
  RemoveRedEyeOutlined,
  RestorePageRounded,
  Send,
} from "@mui/icons-material";
import { ButtonStyled } from "./ButtonStyled";
import { handleExportRows, handlePullRows } from "../../utils/JsonToCsv";
import { handlePrintCsvMany } from "../../utils/handlePrintCsv";
import { LoadingModal } from "./LoadingModal";
import { StateContext } from "../../state-management/app/useContext/StateContext";
import { DispatchContext } from "../../state-management/app/useContext/DispatchContext";
import { getDocById } from "../../services/axios";
import { getInvoiceWithStatements } from "../../utils/hooks/getInvoiceWithStatements";

// import { ViewSingleDoc } from "../../zSimpleDocumentFunctionality/components/ViewSingleDoc"; // GENERIC/PLACEHOLDER/TEMPLATE

export function MRT({
  // associatedPage = {},
  renderModals = true,
  tableType = "",
  data = [],
  dataOriginal = [], // not used for MRT (just for updating documents/notes after creating new ones? (maybe deleting/updating, too))
  setDataOriginal = () => {}, // not used for MRT (just for updating documents/notes after creating new ones? (maybe deleting/updating, too))
  columns,
  isLoading,
  setIsLoading,
  isRefetching,
  setIsRefetching,
  defaultColumnVisibility = {},
  columnVisibility = {},
  setColumnVisibility = () => {},
  tableView = "table",
  setTableView = () => {},
  rowActions = [],
  rowActionMenuItems = [],

  // PROPS (TABLE )
  enableRowVirtualization = true, // TODO (possibly change to true?)

  enableColumnActions = false,
  enableColumnFilterModes = false,

  enableToolbarInternalActions = true,
  enableGlobalFilter = true,
  enableFilters = true,
  enableHiding = true,
  enableDensityToggle = true,
  enableFullScreenToggle = true,

  enableRowSelection = false,

  enableExpanding = false,

  enableRowNumbers = false,
  enablePagination = false,

  enableTableHead = true,
  enableTopToolbar = true,
  enableColumnResizing = true,

  enableBottomToolbar = false,

  // setHoveredRow,
  enableRowHover = true,

  estimatedNumOfRows = 6,

  // initialDensity = "comfortable", // compact, comfortable, spacious
  initialDensity = "compact",

  // maxHeight = "100vh",

  // initialShowGlobalFilter = false,
  open = false,
  setOpen = () => {},

  isDocOpen, // VSDoc (GENERIC/PLACEHOLDER/TEMPLATE)
  setIsDocOpen, // VSDoc (GENERIC/PLACEHOLDER/TEMPLATE)
  selectedDoc = {},
  setSelectedDoc = () => {},

  globalButtons = <></>,

  filterDocs,
  filterDocsStatus,

  isFetchingLocally,
  setIsFetchingLocally = () => {},

  handleClick = () => {},
}) {
  // const [showGlobalFilter, setShowGlobalFilter] = useState(initialShowGlobalFilter)
  const [isError, setIsError] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 2000, // creates MRT error (max = 100... but this allows all rows to be shown at once (required for certain CSV exports))
  });
  // const [rowCount, setRowCount] = useState(0);

  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  // <-- MOVED THE FOLLOWING TO COMPONENT CALLING MRT (can create Modal triggers on cells/when creating column Cell:'s) -->
  // const [open, setOpen] = useState(false)
  // const [selectedDoc, setSelectedDoc] = useState({})
  // function handleClick(doc) {
  //   setOpen(true)
  //   setSelectedDoc(doc)
  // }

  const rowActionsSx = {
    display: "flex",
    flexDirection: "row",
    gap: 0,
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
  };

  // console.log("SORTING", sorting)

  const MAX_ROWS_PULLING = 30;
  const MAX_ROWS_EXPORTING = Infinity;

  function maxSelectedRowsReached(table, type) {
    const selectedRows = table.getSelectedRowModel();
    let maxSelectedRows = Infinity;
    if (type == "pull") {
      maxSelectedRows = MAX_ROWS_PULLING;
      // table.getSelectedRowModel()
    } else if (type == "export") {
      maxSelectedRows = MAX_ROWS_EXPORTING; // was 200 (Ross asked about why maxed 200 JIBs export)
    }

    const maxReached = selectedRows.rows.length > maxSelectedRows;

    return { maxReached, maxSelectedRows };
  }

  function moreThanOneTypeOfInvoice(rows = []) {
    const docTypes = [];
    rows.forEach((row) => {
      row = row.original; //  get row's/doc's actual data
      if (!docTypes.includes(row.docType)) {
        docTypes.push(row.docType);
      }
    });
    return docTypes.length > 1;
  }

  // SKELETON LOADERS (https://github.com/TanStack/table/discussions/2386)
  const tableData = useMemo(
    () => (isLoading ? Array(estimatedNumOfRows).fill({}) : data),
    [isLoading, data]
  );
  const tableColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            Cell: <Skeleton />,
          }))
        : columns,
    [isLoading, columns]
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [displayXRows, setDisplayXRows] = useState(Infinity);
  const [displayedData, setDisplayedData] = useState(tableData);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const handleInputChange = (event) => setDisplayXRows(event.target.value);
  const updateTableRows = (filteredRows) => {
    console.log("filteredRows", filteredRows);
    setDisplayedData(
      filteredRows.slice(0, displayXRows).map((rowObj) => rowObj.original)
    );
    closeModal();
  };
  // useEffect(() => {
  //   if (!isLoading) {
  //     setDisplayedData(tableData)
  //     setDisplayXRows(tableData.length)
  //   }
  // }, [isLoading, tableData])

  async function handleExportInvoices(rows) {
    // console.log("rows before", rows)
    const promises = rows.map(async (row, i) => {
      const client = { ...row.original.client };
      // if (i == 0 || i == rows.length - 1) {
      //   console.log("row", row)
      // }
      const controller = new AbortController();
      // CHANGE
      // row.original = await getDocById("invoices", "_id", row.original._id, controller)
      row.original = await getInvoiceWithStatements(
        row.original._id,
        controller
      );
      row.original.client = client;
      return row;
    });
    rows = await Promise.all(promises);
    // console.log("end")
    // console.log("rows after", rows)

    handlePrintCsvMany(rows, setIsFetchingLocally);
  }

  return (
    <>
      <MaterialReactTable
        // data={data}
        // columns={columns}
        data={tableData}
        // data={displayedData}

        columns={tableColumns}
        // enableColumnFilterModes={enableColumnFilterModes}
        getFacetedUniqueValues={getFacetedUniqueValues()}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        muiTableBodyProps={({ table, row }) => ({
          sx: {
            "&& .MuiTableRow-hover:hover": {
              backgroundColor: appState.theme == "dark" && "#ffffff05",
            },
          },
        })}
        muiTableBodyRowProps={({ table, row }) => ({
          hover: enableRowHover,

          // onDoubleClick: (event) => {
          //   // console.info(event, row.id);

          //   // expand row / toggle detail panel
          //   // https://tanstack.com/table/v8/docs/api/features/expanding
          //   row.toggleExpanded();

          //   // toggle density
          //   // table.setDensity('compact')
          // },
        })}
        // muiTableBodyCellProps={({ cell }) => ({
        //   onDoubleClick: (event) => {
        //     console.info(event, cell.id);
        //   },
        // })}

        displayColumnDefOptions={{
          // https://www.material-react-table.com/docs/guides/display-columns
          "mrt-row-select": {
            size: 40,
          },
          "mrt-row-actions": {
            size: 100,
            muiTableHeadCellProps: {
              // align: 'center', //change head cell props
            },
          },
        }}
        // muiLinearProgressProps={({ isTopToolbar }) => ({
        //   color: 'secondary',
        //   sx: { display: isTopToolbar ? 'block' : 'none' }, //only show top toolbar progress bar
        //   value: fetchProgress, //show precise real progress value if you so desire
        //   variant: 'determinate',
        // })}

        // renderToolbarInternalActions={({ table }) => (
        //   <>
        //     <IconButton onClick={() => showPrintPreview(true)}>
        //       <Print />
        //     </IconButton>

        //     <MRT_ShowHideColumnsButton table={table} />
        //     <MRT_FullScreenToggleButton table={table} />
        //   </>
        // )}

        // muiSelectCheckboxProps={({ row }) => ({
        //   color: 'primary',
        //   disabled: row.original?.isAccountLocked, //access the row data to determine if the checkbox should be disabled
        // })}

        /////////////////
        // ENABLING
        /////////////////
        // https://www.material-react-table.com/docs/api/props#debugTable-prop
        enableBottomToolbar={enableBottomToolbar}
        // enableClickToCopy // click in cell to copy text

        // enableColumnDragging // used together with enableColumnOrdering
        // enableColumnOrdering
        enableColumnResizing={enableColumnResizing}
        enableTableHead={enableTableHead}
        enableTopToolbar={enableTopToolbar}
        // enablePinning // column pinning (left/right)

        enableToolbarInternalActions={enableToolbarInternalActions}
        enableGlobalFilter={enableGlobalFilter}
        enableFilters={enableFilters}
        enableHiding={enableHiding}
        enableDensityToggle={enableDensityToggle}
        enableFullScreenToggle={enableFullScreenToggle}
        // CONDITIONAL RENDERING
        enablePagination={enablePagination}
        enableRowVirtualization={enableRowVirtualization}
        enableColumnActions={enableColumnActions}
        // setHoveredRow={setHoveredRow}

        enableExpanding={enableExpanding}
        renderDetailPanel={
          enableExpanding &&
          (({ row }) => (
            <></>
            // <Box>
            //   <Typography>
            //     Spacing Units:
            //   </Typography>
            //   <Stack>
            //     {row.original.spacingUnits?.toString()}
            //   </Stack>
            // </Box>
            // <Box>
            //   <Typography>
            //     MyWells:
            //   </Typography>
            //   <Stack>
            //     {row.original.wells?.map(well => <Link href={`/wells/${well.wellId}`} >{well.wellId}</Link>)}
            //   </Stack>
            // </Box>
            // <Box
            //   sx={{
            //     display: 'flex',
            //     justifyContent: 'space-around',
            //     alignItems: 'center',
            //   }}
            // >
            //   <img
            //     alt="avatar"
            //     height={200}
            //     src={"https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/401.jpg"}
            //     loading="lazy"
            //     style={{ borderRadius: '50%' }}
            //   />
            //   <Box sx={{ textAlign: 'center' }}>
            //     <Typography variant="h4">Signature Catch Phrase:</Typography>
            //     <Typography variant="h1">
            //       &quot;Hello&quot;
            //     </Typography>
            //   </Box>
            // </Box>
          ))
        }
        enableRowActions={
          rowActionMenuItems.length || rowActions.length ? true : false
        }
        renderRowActions={
          rowActions.length &&
          (({ row }) => (
            <div>
              {/* <Button color="inherit" onClick={() => handleClick(row.original)} startIcon={<RemoveRedEyeOutlined />}>View</Button> */}

              {/* {rowActions.type == "single"} */}
              {/* <IconButton><AddCircleTwoTone /></IconButton> */}
              {/* <IconButton><AddCircleOutlineTwoTone /></IconButton> */}
              {/* <IconButton><RemoveRedEyeOutlined /></IconButton> */}
              {/* <IconButton onClick={() => handleClick(row.original)}><RemoveRedEyeOutlined /></IconButton> */}
              {/* {rowActions.map(action => action)} */}
            </div>
          ))
        }
        renderRowActionMenuItems={
          rowActionMenuItems.length &&
          (({ closeMenu }) => [
            <></>,
            // <MenuItem
            //   key={0}
            //   component="div"
            //   onClick={() => {
            //     // View profile logic...
            //     closeMenu();
            //   }}
            //   // sx={{ m: 0 }}
            //   sx={{
            //     display: "flex",
            //     flexDirection: "row",
            //     gap: 0,
            //     justifyContent: "center",
            //     alignItems: "center",
            //     padding: "8px",
            //   }}
            // >
            //   <ListItemIcon>
            //     <AccountCircle />
            //   </ListItemIcon>
            //   View Profile
            // </MenuItem>,

            // <MenuItem
            //   key={1}
            //   onClick={() => {
            //     // Send email logic...
            //     closeMenu();
            //   }}
            //   sx={{ m: 0 }}
            //   // sx={rowActionsSx}
            // >
            //   <ListItemIcon>
            //     <Send />
            //   </ListItemIcon>
            //   Send Email
            // </MenuItem>,
          ])
        }
        // renderRowActions={({ row }) => (
        //   <div>
        //     {rowActions.type == "single"}
        //     <IconButton><AddCircleTwoTone /></IconButton>
        //     <IconButton><AddCircleOutlineTwoTone /></IconButton>
        //   </div>
        // )}

        enableRowSelection={enableRowSelection}
        // onRowSelectionChange={(updaterOrValue) => {
        //   if (typeof updaterOrValue === "function") {
        //     myCustomRowSelectionSetter(updaterOrValue(rowSelection));
        //   } else {
        //     myCustomRowSelectionSetter(updaterOrValue);
        //   }
        // }}

        enableRowNumbers={enableRowNumbers}
        // enableRowDragging // used together with enableRowOrdering (most likely)
        // enableRowOrdering

        enableStickyHeader
        // Table Footer ("Rows per page", etc.)
        // muiBottomToolbarProps={{
        //   sx: {
        //     boxShadow: "none",
        //     }
        // }}

        // Fixes vertical alignment for Table Footer pagination labels
        muiTablePaginationProps={{
          sx: {
            "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
              {
                margin: "auto",
              },
          },
          rowsPerPageOptions: [10, 30, 50, 100, 1000, 2000],
          // showFirstButton: false,
          // showLastButton: false,
        }}
        muiTableContainerProps={{ sx: { maxHeight: "70vh" } }} // was previously trying to use maxHeight prop -- was causing SLOW RENDERING/TABLE ACTIONS (unsure exactly why...)
        // enableRowSelection

        // enableColumnOrdering
        // enableGlobalFilter={false} //turn off a feature

        // OTHER
        getRowId={(row) => row._id}
        localization={{
          noRecordsToDisplay:
            tableType == "invoices"
              ? "No invoices set for this well."
              : "No data found.",
        }}
        // manualFiltering
        // manualPagination
        // manualSorting
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        // rowCount={rowCount}
        initialState={{
          showColumnFilters: true,
          density: initialDensity,
          // columnVisibility: defaultColumnVisibility,
        }}
        state={{
          columnFilters,
          globalFilter,
          // showGlobalFilter: showGlobalFilter,

          isLoading,
          pagination,
          showSkeletons: isRefetching || isLoading,
          showAlertBanner: isError,
          showProgressBars: isRefetching || isLoading,
          columnVisibility: columnVisibility,
          // showProgressBars: isLoading || isRefetching,
          sorting,

          // hoveredRow: disableRowHovering && null,
        }}
        onColumnVisibilityChange={setColumnVisibility}
        renderTopToolbarCustomActions={({ table }) => {
          // https://www.material-react-table.com/docs/examples/data-export

          // const totalRows = table.getRowModel().rows.length
          const totalRows = data.length; // TODO: should use appropriate MRT/TanStack table method instead...
          const filteredRows = table.getFilteredRowModel().rows;
          const filteredRowsLength = filteredRows.length;

          const selectedRows = table.getSelectedRowModel().rows;
          const notYetPulled = selectedRows.some(
            (row) =>
              !row.original.statements || row.original.statements.length == 0
          );
          const moreThanOneTypeOfInvoiceBool = moreThanOneTypeOfInvoice(
            table.getSelectedRowModel().rows
          );

          return tableType == "invoices" ? (
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              // sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
            >
              <Typography variant="body2" sx={{ opacity: 0.6, mr: 1 }}>
                {isLoading
                  ? "Loading..."
                  : `Showing ${filteredRowsLength} of ${totalRows} rows`}
              </Typography>

              {/* <ButtonStyled
                  onClick={openModal}
                >
                  #rows
                </ButtonStyled>
                <Dialog open={modalOpen} onClose={closeModal}>
                  <DialogTitle>Enter Row Count</DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="rowCount"
                      label="Row Count"
                      type="number"
                      fullWidth
                      variant="standard"
                      value={displayXRows}
                      onChange={handleInputChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <ButtonStyled onClick={() => updateTableRows(filteredRows)}>Update</ButtonStyled>
                    <ButtonStyled onClick={closeModal}>Cancel</ButtonStyled>
                  </DialogActions>
                </Dialog> */}

              {/* PULL BUTTON */}
              <ButtonStyled
                disabled={
                  (!table.getIsSomeRowsSelected() &&
                    !table.getIsAllRowsSelected()) ||
                  maxSelectedRowsReached(table, "pull")?.maxReached ||
                  isFetchingLocally
                }
                onClick={() => {
                  setIsFetchingLocally(true);
                  handlePullRows(
                    table,
                    setDataOriginal,
                    setIsFetchingLocally,
                    appDispatch
                  );
                }}
                startIcon={<AutorenewRounded />}
                variant="contained"
              >
                {isFetchingLocally
                  ? "Pulling Rows..."
                  : maxSelectedRowsReached(table, "pull").maxReached
                  ? `Pull Selected Rows (max ${
                      maxSelectedRowsReached(table, "pull").maxSelectedRows
                    })`
                  : `Pull Selected Rows`}
              </ButtonStyled>

              {/* EXPORT BUTTON */}
              <ButtonStyled
                disabled={
                  (!table.getIsSomeRowsSelected() &&
                    !table.getIsAllRowsSelected()) ||
                  maxSelectedRowsReached(table, "export")?.maxReached ||
                  isFetchingLocally ||
                  notYetPulled ||
                  moreThanOneTypeOfInvoiceBool
                }
                //only export selected rows
                // onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                onClick={() => {
                  setIsFetchingLocally(true);
                  handleExportInvoices(table.getSelectedRowModel().rows);
                }}
                startIcon={<FileDownloadRounded />}
                variant="contained"
              >
                {isFetchingLocally
                  ? "Exporting Selected Rows..."
                  : maxSelectedRowsReached(table, "export").maxReached
                  ? `Export Selected Rows (max ${
                      maxSelectedRowsReached(table, "export").maxSelectedRows
                    })`
                  : notYetPulled
                  ? `Export Selected Rows (all rows must be pulled)`
                  : moreThanOneTypeOfInvoiceBool
                  ? `Export Selected Rows (cannot export JIB & REVENUE)`
                  : `Export Selected Rows`}
              </ButtonStyled>

              {globalButtons}
            </Stack>
          ) : (
            <Stack direction="row" gap={1} alignItems="center">
              <Typography variant="body2" sx={{ opacity: 0.6, mr: 1 }}>
                {isLoading
                  ? "Loading..."
                  : `Showing ${filteredRowsLength} of ${totalRows} rows`}
              </Typography>

              {globalButtons}
            </Stack>
          );
        }}
      ></MaterialReactTable>

      {renderModals && (
        <>
          {/* <LoadingModal open={isFetching} setOpen={setIsFetching} /> */}
          {/* <ViewSingleDocument doc={selectedDoc} open={open} setOpen={setOpen} documents={dataOriginal} setDocuments={setDataOriginal} />
          <ViewSingleNote doc={selectedDoc} open={isNoteOpen} setOpen={setIsNoteOpen} notes={dataOriginal} setNotes={setDataOriginal} />
          <ViewSingleDoc doc={selectedDoc} open={isDocOpen} setOpen={setIsDocOpen} docs={dataOriginal} setDocs={setDataOriginal} /> */}
        </>
      )}
    </>
  );
}
