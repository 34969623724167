import React, { useState, useEffect, useContext } from "react"
import { useParams } from 'react-router-dom'

import { Stack } from "@mui/material"
import { Person2TwoTone } from "@mui/icons-material"

import { StateContext } from "../../state-management/app/useContext/StateContext"
// import { DispatchContext } from "../../state-management/app/useContext/DispatchContext"

import { PageContainer } from "../../components/layout/PageContainer"
import { PageTitle } from "../../components/layout/PageTitle"
import { LoadingModal } from "../../components/utils/LoadingModal"
import { ButtonStyled } from "../../components/utils/ButtonStyled"

import { useFetchDocsById } from "../../utils/hooks/useFetchDocsById"
import { useFetchDocById } from "../../utils/hooks/useFetchDocById"
import { useScrapeInvoices } from "../../utils/hooks/webscraping/useScrapeInvoices"

import { TableInvoices } from "./components/TableInvoices"

import { SCRAPETYPE } from "../../utils/constants"



export default function Client({

}) {
  //////////////////////////
  // STATE
  //////////////////////////

  // GENERAL
  const id = useParams().id
  const appState = useContext(StateContext)

  // DOCSORIGINAL 
  const [project, setProject] = useState(true) // true = get statements.length (instead of all statements/statementdetails) // change to false for error deeper checking 
  const {
    docs: docsOriginal,
    isFetching: isFetchingDocsOriginal,
    isRefetching: isRefetchingDocsOriginal,
    setIsRefetching: setIsRefetchingDocsOriginal
  } = useFetchDocsById(id, "clientELid", "invoices", { project: project, consoleLog: true })
  const [docsFinal, setDocsFinal] = useState([])
  
  // DOCSORIGINAL MODIFICATIONS
  const {doc: client, isFetching: isFetchingClient } = useFetchDocById(id, "ELid", "clients")

  // ADDITIONAL
  const [isLoadingTable, setIsLoadingTable] = useState(true)

  const { scrapeInvoices, scrapedInvoices, isScrapingInvoices } = useScrapeInvoices(SCRAPETYPE.getInvoicesOfClients, client, appState)

  {/* TODO:  invoice.errors (docsOriginal/docsFinal.reduce(invoice => invoice.errors...)) */}

  



  //////////////////////////
  // EFFECTS
  //////////////////////////

  // UPDATE DOCSFINAL WITH DOCSORIGINAL (UNMODIFIED)
  useEffect(() => {
    if (isFetchingDocsOriginal) return


    if (docsOriginal.length > 0 && modificationsNotPresent(docsOriginal)) { // if initial page load (!modificationsPresent) && docs exist, start showing table to user (no modifications present, but they aren't needed until user clicks something)
      setDocsFinal(docsOriginal)
      setIsLoadingTable(false) // start showing table to user
    }
    else if (docsOriginal.length == 0) { // if initial page load && no docs, no modifications to be done (show table -- "no data" version)
      setIsLoadingTable(false)
    }
  }, [docsOriginal])


  // UPDATE DOCSFINAL WITH MODIFICATIONS
  useEffect(() => {
    if (docsFinal.length == 0) return
    

    let modificationsMade = 0
    let docsModified = docsFinal


    // Modification 1 (add doc.client property)
    const clientReady = client._id
    const clientNotPresent = clientReady && modificationsNotPresent(docsFinal, "client")
    if (clientNotPresent) {
      docsModified = docsModified.map(doc => !doc.client ? {...doc, client: client} : doc)
      modificationsMade++
      // console.log("docsModifiedWithClient", docsModified)
    }


    // Set State (after modifications)
    if (modificationsMade > 0) {
      setDocsFinal(docsModified)
    }

  }, [docsFinal, client])


  // UPDATE DOCSFINAL WITH NEWLY SCRAPED DOCS (INVOICES)
  useEffect(() => {
    if (scrapedInvoices.length == 0) return

    const newDocs = []
    scrapedInvoices.forEach(scrapedInvoice => {
      if (docsFinal.some(doc => doc.docNum == scrapedInvoice.docNum)) return 
      newDocs.push(scrapedInvoice)
    })
    if (newDocs.length > 0) {
      setDocsFinal([...docsFinal, ...newDocs])
    }
  }, [scrapedInvoices])


  

  //////////////////////////
  // FUNCTIONS
  //////////////////////////

  function modificationsNotPresent(docs, modification) {
    // modifications should be considered "not present" even if only one doc is missing the modification (could mess up future functions/events for that doc)

    if (modification == "client") {
      const noClient = docs.some(doc => !doc.client)
      return noClient
    }

    const noClient = docs.some(doc => !doc.client)
    const modificationsNotPresent = noClient
    return modificationsNotPresent
  }



  //////////////////////////
  // RENDER
  //////////////////////////
  
  let pageTitle = client.name

  {/* TODO:  invoice.errors */}
  let pageTitleComponent =
    <PageTitle
      icon={<Person2TwoTone sx={{fontSize: "4rem"}} />}
      pageTitleName={pageTitle}
      contentBelowTitle={
        client._id ?
          client.initialInvoicesFetched ?
              <Stack direction="row" gap={2}>
                <ButtonStyled
                  onClick={scrapeInvoices}
                  disabled={isScrapingInvoices}
                  sx={{ml: 1}}
                >
                  Find New Invoices
                </ButtonStyled>

                {/* <ButtonStyled
                  onClick={() => {setProject(false); setIsRefetchingDocsOriginal(true)}}
                  disabled={project == false}
                  // sx={{ml: 1}}
                >
                  Error Check
                </ButtonStyled> */}
              </Stack>
            :
              <Stack direction="row" gap={2}>
                <ButtonStyled
                  onClick={scrapeInvoices}
                  disabled={isScrapingInvoices}
                  sx={{ml: 1}}
                >
                  Get Initial Invoices
                </ButtonStyled>
              </Stack>
          : <></>
      }
    />


  return (
    <>
        <PageContainer
          title={pageTitleComponent}
        >

          <TableInvoices
            mainPage
            docsOriginal={docsFinal} // dataOriginal (MRT)
            setDocsOriginal={setDocsFinal} // setDataOriginal (MRT)
            isLoading={isLoadingTable} // allows skeleton while true
            setIsLoading={setIsLoadingTable}
            // additional data
            client={client}
          />

        </PageContainer>


      <LoadingModal open={isScrapingInvoices} />
      <LoadingModal open={isRefetchingDocsOriginal} />
    </>
  )
}
