import React, { useState, useEffect, useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  ListItem,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AutorenewRounded,
  CalendarTodayRounded,
  ClearRounded,
  FileDownloadRounded,
  PublishedWithChangesRounded,
} from "@mui/icons-material";

import MaterialReactTable, { MRT_FilterTextField } from "material-react-table"; // only needed for testing
import { getFacetedUniqueValues } from "@tanstack/react-table"; // only needed for testing

import { StateContext } from "../../../state-management/app/useContext/StateContext";

import { LinkWrapped } from "../../../components/utils/LinkWrapped";
import { MRT } from "../../../components/utils/MRT";
import { Pod } from "../../../components/utils/Pod";
import { ButtonStyled } from "../../../components/utils/ButtonStyled";
import { formatDate } from "../../../utils/formatDate";
import { SCRAPETYPE } from "../../../utils/constants";
import { formatToTanStackTableColumn } from "../../../utils/formatToTanStackTableColumn";
import { ModalInvoicesCustomColumn } from "./ModalInvoicesCustomColumn";
import { IconButtonStyled } from "../../../components/utils/IconButtonStyled";
import {
  handlePrintCsvJib,
  handlePrintCsvRevenue,
} from "../../../utils/handlePrintCsv";
import { LoadingModal } from "../../../components/utils/LoadingModal";
import { isValidDate, isValidDateStr } from "../../../utils/isValidDate";
import { ModalGeneric } from "../../../components/utils/ModalGeneric";
import { get } from "lodash";
import { getDocById } from "../../../services/axios";
import { useScrapeInvoices } from "../../../utils/hooks/webscraping/useScrapeInvoices";
import { sortingFnRowStrToNum } from "../../../utils/sortingFnRowStrToNum";
import { filterDates } from "../../../utils/filterDates";
import { ifParenthesesStrConvertToNegNum } from "../../../utils/ifParenthesesStrConvertToNegNum";
import { TypographyError } from "../../../components/utils/TypographyError";
import { checkErrorsInvoice } from "../../../utils/errorChecking";
import { nanoid } from "nanoid";
import { TypographyWarning } from "../../../components/utils/TypographyWarning";
import { ButtonMoreInfo } from "../../../components/utils/ButtonMoreInfo";
import { getInvoiceWithStatements } from "../../../utils/hooks/getInvoiceWithStatements";

export function TableInvoices({
  docsOriginal,
  setDocsOriginal,

  isLoading, // isLoadingTable
  setIsLoading,

  tablePodViewVisible = true,
  initialSettings = {
    tableView: "table",
  },

  enableTableHeader = true,

  sx = {},

  // additional
  client = {},
}) {
  const DOC_SINGULAR = "invoice";
  const DOCS_PLURAL = "invoices";

  const { id: clientELid } = useParams();

  //////////////////////////
  // STATE
  //////////////////////////

  // GENERAL
  const appState = useContext(StateContext);
  const [docsFinal, setDocsFinal] = useState(docsOriginal);

  // TABLE
  const defaultColumnVisibility = {
    createdDate: false,
    updatedDate: false,
  };
  const [columnVisibility, setColumnVisibility] = useState(
    defaultColumnVisibility
  );

  // ADDITIONAL
  const { scrapeInvoices, scrapedInvoices, isScrapingInvoices } =
    useScrapeInvoices(
      SCRAPETYPE.getInvoicesAndStatementsOfClients,
      client,
      appState
    );
  // used in MRT child component (fetching >1 invoice), so can't get rid of and use isScrapingInvoices
  // ...consider handling fetching >1 invoice within MRT (then, can remove from here & only use isScrapingInvioces ?)
  const [isFetchingLocally, setIsFetchingLocally] = useState(false);

  const [openModalCustomColumn, setOpenModalCustomColumn] = useState(false);
  const [customColumns, setCustomColumns] = useState([]);
  const [customColumnsDb, setCustomColumnsDb] = useState(
    handleDbCustomColumns(docsOriginal.at(0))
  );

  const [selectedColumnToFilterDateRange, setSelectedColumnToFilterDateRange] =
    useState("cashDate");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [openModalSelectColumn, setOpenModalSelectColumn] = useState(false);

  //////////////////////////
  // EFFECTS
  //////////////////////////

  // update docsOriginal (parent's docsFinal) w/ scraped Invoices
  useEffect(() => {
    if (isScrapingInvoices) return;

    setIsFetchingLocally(false);
    if (scrapedInvoices.length > 0) {
      const updatedInvoice = scrapedInvoices?.at(0);
      setDocsOriginal(
        docsOriginal.map((doc) =>
          doc.invoiceId == updatedInvoice.invoiceId ? updatedInvoice : doc
        )
      );
    }
  }, [scrapedInvoices, isScrapingInvoices]);

  // CUSTOM COLUMNS (add to docsOriginal (prop))
  useEffect(() => {
    setCustomColumnsDb(handleDbCustomColumns(docsOriginal.at(0)));
    setDocsFinal(docsOriginal);
  }, [docsOriginal]);

  // FILTER COLUMN(S) (BY DATE RANGE)
  useEffect(() => {
    if (docsOriginal.at(0)?.client) {
      // why is client the validation here?
      const isValidFromDate = isValidDate(new Date(fromDate));
      const isValidToDate = isValidDate(new Date(toDate));
      if (isValidFromDate || isValidToDate) {
        // console.log("docsLength", docsFinal.length)
        const filteredDocs = filterDocsByDateRange(
          docsOriginal,
          selectedColumnToFilterDateRange,
          fromDate,
          toDate
        );
        // console.log("docsLength", filteredDocs.length)
        setDocsFinal(filteredDocs);
      } else {
        setDocsFinal(docsOriginal);
      }
    }
  }, [fromDate, toDate, selectedColumnToFilterDateRange]);

  //////////////////////////
  // FUNCTIONS
  //////////////////////////
  function handleInvoicePull(invoice, table) {
    setIsFetchingLocally(true);

    // let scrapeInvoicesArg = {client: {ELid: invoice.clientELid}, invoiceUrl: invoice.invoiceUrl}  // NEW (NOPE -- doesn't work on scraping script)
    let scrapeInvoicesArg = {
      client: { ELid: invoice.clientELid },
      invoiceId: invoice.invoiceId,
      table: table,
    }; // OLD

    console.log(scrapeInvoicesArg);
    scrapeInvoices(scrapeInvoicesArg);
  }

  function handleDbCustomColumns(doc = {}) {
    if (!doc || !doc?.custom) return [];

    const customColumnKeys = Object.keys(doc.custom) || [];
    if (customColumnKeys.length == 0) return [];

    return customColumnKeys.map((key) =>
      formatToTanStackTableColumn(`custom.${key}`, setDocsOriginal)
    );
  }

  // get invoice from db (since statements: statements.length sent originally (vs actual statements))
  async function handleGetInvoicesFromDb(rows) {
    const promises = rows.map(async (row, i) => {
      const client = { ...row.original.client };
      const controller = new AbortController();
      // CHANGE
      // row.original = await getDocById("invoices", "_id", row.original._id, controller)
      row.original = await getInvoiceWithStatements(
        row.original._id,
        controller
      );
      row.original.client = client;
      return row;
    });
    rows = await Promise.all(promises);
    return rows.at(0);
  }

  async function handleExportInvoice(row, type = "REVENUE") {
    row = await handleGetInvoicesFromDb([row]);

    if (type == "REVENUE") {
      handlePrintCsvRevenue(row.original, client);
    } else if (type == "JIB") {
      handlePrintCsvJib(row.original, client);
    }
  }

  function filterDocsByDateRange(docs, key = "", fromDate, toDate) {
    if (key == "cashDate") key = "custom.cashDate";

    const isValidFromDate = isValidDateStr(fromDate);
    const isValidToDate = isValidDateStr(toDate);
    if (isValidFromDate || isValidToDate) {
      return docs.filter((doc) => {
        const isValidDate = isValidDateStr(get(doc, key));
        const date = new Date(get(doc, key));
        if (isValidDate) {
          if (isValidFromDate && date < new Date(fromDate)) {
            return false;
          }
          if (isValidToDate && date > new Date(toDate)) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      });
    } else {
      return docs;
    }
  }

  // function isValidDateGithubVersion(d) {
  //   const parsedDate = new Date(d)
  //   return parsedDate instanceof Date && !isNaN(parsedDate)
  // }

  // function dateBetweenFilterFn(rows, id, filterValues) {
  //   let sd = new Date(filterValues[0]);
  //   let ed = new Date(filterValues[1]);
  //   if (!isValidDateGithubVersion(filterValues[0]) || !isValidDateGithubVersion(filterValues[1])) return rows
  //   return rows.filter(r => {
  //     var time = new Date(r.values[id]);
  //     console.log(time, ed, sd)
  //     if (filterValues.length === 0) return rows;
  //     return (time >= sd && time <= ed);
  //   });
  // }

  // dateBetweenFilterFn.autoRemove = val => !val;

  //////////////////////////
  // TABLE
  //////////////////////////

  // const filterModes = ['fuzzy', 'contains', 'startsWith']

  const columns = useMemo(
    () => [
      {
        accessorKey: "pulled",
        header: "Status",
        size: 100,
        minSize: 100,
        maxSize: 100,
        // enableColumnFilter: false,
        // enableColumnActions: false,
        enableColumnFilterModes: false,

        filterVariant: "multi-select",
        filterSelectOptions: [
          { text: "Pulled", value: "pulled" },
          { text: "Repulled", value: "repulled" },
          { text: "Not Pulled", value: "notPulled" },
        ],
        filterFn: (row, id, filterValue) => {
          const pulled =
            row.original.statements > 0 ||
            (Array.isArray(row.original.statements) &&
              row.original.statements?.length > 0);
          const repulled = row.original.statementsRepulled?.bool || false;

          return filterValue.length > 0
            ? filterValue.flat().includes("pulled") &&
              filterValue.flat().includes("repulled")
              ? pulled || repulled
              : filterValue.flat().includes("pulled")
              ? pulled
              : filterValue.flat().includes("repulled")
              ? repulled
              : filterValue.flat().includes("notPulled")
              ? !pulled && !repulled
              : true
            : true;
        },

        Cell: ({ row, table }) => {
          const pulled =
            row.original.statements > 0 ||
            (Array.isArray(row.original.statements) &&
              row.original.statements?.length > 0);
          const docType = row.original.docType || "";
          // const isChecked = row.getIsSelected()

          return (
            <Stack alignItems="start">
              <Stack direction="row" alignItems="center">
                {pulled && (
                  <>
                    {docType == "REVENUE" && (
                      <IconButtonStyled
                        // color={isChecked ? "secondary" : "primary"}
                        onClick={() => handleExportInvoice(row, "REVENUE")}
                        toolTip="Export as CSV"
                      >
                        <FileDownloadRounded />
                      </IconButtonStyled>
                    )}
                    {docType == "JIB" && (
                      <IconButtonStyled
                        // color={isChecked ? "secondary" : "primary"}
                        onClick={() => handleExportInvoice(row, "JIB")}
                        toolTip="Export as CSV"
                      >
                        <FileDownloadRounded />
                      </IconButtonStyled>
                    )}

                    <IconButtonStyled
                      onClick={() => handleInvoicePull(row.original, table)}
                      disabled={isFetchingLocally}
                      toolTip={
                        row.original.statementsRepulled?.bool
                          ? `Repull (last ${formatDate(
                              new Date(row.original.statementsRepulled.date)
                            )})`
                          : `Repull (initial ${formatDate(
                              new Date(
                                row.original.updatedDate ||
                                  row.original.createdDate
                              )
                            )})`
                      }
                      sx={{
                        opacity: row.original.statementsRepulled?.bool
                          ? 0.6
                          : 0.6,
                      }}
                    >
                      {row.original.statementsRepulled?.bool ? (
                        <PublishedWithChangesRounded />
                      ) : (
                        <AutorenewRounded />
                      )}
                    </IconButtonStyled>
                  </>
                )}

                {!pulled && (
                  <ButtonStyled
                    onClick={() => handleInvoicePull(row.original, table)}
                    disabled={isFetchingLocally}
                    startIcon={<AutorenewRounded />}
                    label="Pull"
                  />
                )}
              </Stack>
            </Stack>
          );
          // <LinkWrapped to={`/invoices/${row.original._id}`}>{row.original.amtOriginal}</LinkWrapped>
        },
      },
      {
        accessorKey: "orgNum",
        header: "orgNum",
        size: 80,
        enableColumnFilterModes: false,
        // Cell: ({ row }) => `${row.original.orgNum} - ${row.original.invoiceId}`
      },
      {
        accessorKey: "docType",
        header: "docType",
        size: 100,
        enableColumnFilterModes: false,
        filterVariant: "select",
        filterSelectOptions: [
          { text: "Revenue", value: "REVENUE" },
          { text: "JIB", value: "JIB" },
        ],
        filterFn: (row, id, filterValue) =>
          filterValue
            ? row.original.docType == filterValue
              ? true
              : false
            : true,
      },
      {
        accessorKey: "operator",
        header: "operator",
        // columnFilterModeOptions: ,
        // enableColumnFilterModes: true,
        Filter: Filter,
        // <div>
        //   <Filter column={header.column} table={table} />
        // </div>
        // renderColumnFilterModeMenuItems: ({ column, onSelectFilterMode }) => filterModes.map((filterMode) => (
        //   <MenuItem
        //     key={filterMode}
        //     onClick={() => onSelectFilterMode(filterMode)}
        //   >
        //     {filterMode}
        //   </MenuItem>
        // )),
      },
      {
        accessorKey: "opOwnerNum",
        header: "opOwnerNum",
        size: 100,
        sortingFn: "alphanumeric",
        enableColumnFilterModes: false,
      },
      {
        accessorKey: "docNum",
        header: "docNum",
        size: 100,
        sortingFn: "alphanumeric",
        enableColumnFilterModes: false,
      },
      {
        accessorKey: "docDate",
        header: "docDate",
        size: 100,
        sortingFn: "datetime",
        // filterVariant: "text",
        // filterFn: (row, id, filterValue) => row.original.docDate?.includes(filterValue),

        // filterFn: "betweenInclusive",

        filterVariant: "range",
        filterFn: filterDates,
        muiTableHeadCellFilterTextFieldProps: ({
          table,
          column,
          rangeFilterIndex,
        }) => ({
          placeholder: rangeFilterIndex == 0 ? `From` : `To`,
        }),
        muiTableHeadCellProps: ({ table, column }) => ({
          sx: {
            "& .MuiCollapse-root .MuiBox-root": {
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            },
          },
        }),

        //   <div class="MuiCollapse-root MuiCollapse-vertical MuiCollapse-entered css-pwcg7p-MuiCollapse-root" style="min-height: 0px;">
        //     <div class="MuiCollapse-wrapper MuiCollapse-vertical css-smkl36-MuiCollapse-wrapper">
        //       <div class="MuiCollapse-wrapperInner MuiCollapse-vertical css-9l5vo-MuiCollapse-wrapperInner">

        //         <div class="MuiBox-root css-lif8nm">

        //           <div class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root css-nm4lca-MuiFormControl-root-MuiTextField-root">
        //             <div class="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd css-1xlxpxc-MuiInputBase-root-MuiInput-root">
        //               <input aria-invalid="false" id=":r60f:" placeholder="From" type="text" title="Min" class="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedEnd css-193ajjd-MuiInputBase-input-MuiInput-input" value="">
        //               <div class="MuiInputAdornment-root MuiInputAdornment-positionEnd MuiInputAdornment-standard MuiInputAdornment-sizeMedium css-1vhgub1-MuiInputAdornment-root">
        //                 <span aria-label="Clear filter" class="" data-mui-internal-clone-element="true">
        //                   <button class="MuiButtonBase-root Mui-disabled MuiIconButton-root Mui-disabled MuiIconButton-sizeSmall css-k8j7af-MuiButtonBase-root-MuiIconButton-root" tabindex="-1" type="button" aria-label="Clear filter" disabled="">
        //                     <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon">
        //                       <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
        //                     </svg>
        //                   </button>
        //                 </span>
        //               </div>
        //             </div>
        //           </div>

        //           <div class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root css-nm4lca-MuiFormControl-root-MuiTextField-root">
        //             <div class="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd css-1xlxpxc-MuiInputBase-root-MuiInput-root">
        //               <input aria-invalid="false" id=":r60f:" placeholder="From" type="text" title="Min" class="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedEnd css-193ajjd-MuiInputBase-input-MuiInput-input" value="">
        //               <div class="MuiInputAdornment-root MuiInputAdornment-positionEnd MuiInputAdornment-standard MuiInputAdornment-sizeMedium css-1vhgub1-MuiInputAdornment-root">
        //                 <span aria-label="Clear filter" class="" data-mui-internal-clone-element="true">
        //                   <button class="MuiButtonBase-root Mui-disabled MuiIconButton-root Mui-disabled MuiIconButton-sizeSmall css-k8j7af-MuiButtonBase-root-MuiIconButton-root" tabindex="-1" type="button" aria-label="Clear filter" disabled="">
        //                     <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon">
        //                       <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
        //                     </svg>
        //                   </button>
        //                 </span>
        //               </div>
        //             </div>
        //           </div>

        //         </div>

        //       </div>
        //     </div>
        // </div>

        // Filter: VerticalRangeFilter,

        // Filter: FilterDates,
        // Filter: FilterDates2,

        // enableColumnFilterModes: false,
        // filterFn: 'contains',

        // filterVariant: 'range',
        // muiTableHeadCellFilterTextFieldProps: {
        //   type: 'date'
        // },
        // filterFn: (row, id, filterValue) => {
      },
      {
        accessorKey: "receivedDate",
        header: "receivedDate",
        size: 100,
        sortingFn: "datetime",

        filterVariant: "range",
        filterFn: filterDates,
        muiTableHeadCellFilterTextFieldProps: ({
          table,
          column,
          rangeFilterIndex,
        }) => ({
          placeholder: rangeFilterIndex == 0 ? `From` : `To`,
        }),
        muiTableHeadCellProps: ({ table, column }) => ({
          sx: {
            "& .MuiCollapse-root .MuiBox-root": {
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            },
          },
        }),

        // enableColumnFilterModes: false,
        // filterVariant: "text",
        // filterFn: (row, id, filterValue) => row.original.receivedDate?.includes(filterValue),
        // filterFn: 'contains',

        // Filter: DateRangeFilter, // custom filter component
        // filterFn: (row, id, filterValue) => {
        //   const key = "receivedDate"
        //   const fromDate2 = new Date(fromDate)
        //   const toDate2 = new Date(toDate)
        //   const isValidFromDate = isValidDate(fromDate2)
        //   const isValidToDate = isValidDate(toDate2)
        //   console.log(fromDate2)
        //   console.log(toDate2)
        //   if (isValidFromDate || isValidToDate) {
        //     const date = new Date(row.original[key]);
        //     if (isValidFromDate && date < new Date(fromDate2)) {
        //       return false;
        //     }
        //     if (isValidToDate && date > new Date(toDate2)) {
        //       return false;
        //     }
        //     return true;
        //   }
        //   else {
        //     return true
        //   }
        // }
      },
      {
        accessorKey: "amtOriginal",
        header: "amtOriginal",
        size: 100,
        enableColumnFilterModes: false,
        sortingFn: (a, b) => sortingFnRowStrToNum(a, b, "amtOriginal"),

        filterFn: (row, id, filterValue) => {
          if (filterValue == "*") {
            const pulled =
              row.original.statements > 0 ||
              (Array.isArray(row.original.statements) &&
                row.original.statements?.length > 0);
            if (pulled) {
              // const { errors, invoiceAndStatementDetailsMatch } = checkErrorsInvoice(row.original)
              // return !invoiceAndStatementDetailsMatch
              // const invoiceAndStatementDetailsMatchFrontEnd = true // const errorsFrontEndUnfiltered = [] // maybe don't need FE checking? (Entirely Redundant?)
              /////////
              // TODO: BRING BACK ERROR CHECKING
              /////////
              // const {
              //   errors,
              //   invoiceAndStatementDetailsMatch:
              //     invoiceAndStatementDetailsMatchFrontEnd,
              // } = checkErrorsInvoice(row.original);
              // let invoiceAndStatementDetailsMatchBackEnd =
              //   row.original.invoiceAndStatementDetailsMatch === false
              //     ? false
              //     : true;
              // let invoiceAndStatementDetailsMatch =
              //   invoiceAndStatementDetailsMatchFrontEnd &&
              //   invoiceAndStatementDetailsMatchBackEnd;
              // return !invoiceAndStatementDetailsMatch;
              /////////
              /////////
              // return errors?.some(e => e.includes("statementDetails empty")) // lookign for statementDetails empty (temporary testing, invoices table pulling all statements)
            }
            return false;
            // const error1 = (row.original.amtOriginal && row.original.totals?.total) ? ifParenthesesStrConvertToNegNum(row.original.amtOriginal) != row.original.totals.total?.toFixed(2) : false
            // return error1
          }
          return row.original.amtOriginal?.includes(filterValue);
        },

        Cell: ({ row }) => {
          const pulled =
            row.original.statements > 0 ||
            (Array.isArray(row.original.statements) &&
              row.original.statements?.length > 0);
          if (pulled) {
            const linkComponent = (
              <LinkWrapped
                to={`/clients/${clientELid}/invoices/${row.original._id}`}
              >
                {row.original.amtOriginal || "Go to JIB"}
              </LinkWrapped>
            );

            // const errorsFrontEndUnfiltered = [] // maybe don't need FE checking? (Entirely Redundant?)
            /////////
            // TODO: BRING BACK ERROR CHECKING
            /////////

            // const {
            //   errors: errorsFrontEndUnfiltered,
            //   invoiceAndStatementDetailsMatch,
            // } = checkErrorsInvoice(row.original);
            // // TODO:  consider eliminating text after second * (the details of it -- new now to show statementX vs calculated X)
            // const errorsFrontEnd = errorsFrontEndUnfiltered || [];
            // const errorsBackEnd = row.original.errors || [];
            // const errors = [];
            // errorsFrontEnd.forEach((e) =>
            //   errorsBackEnd.includes(e)
            //     ? errors.push(`${e}-FE/BE`)
            //     : errors.push(`${e}-FE`)
            // );
            // errorsBackEnd.forEach((e) =>
            //   errorsFrontEnd.includes(e) ? null : errors.push(`${e}-BE`)
            // );
            /////////
            /////////

            const errors = [];

            if (errors.length > 0) {
              return (
                <Stack>
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    {linkComponent}
                    {row.original.docType == "JIB" &&
                      row.original.errors === undefined && (
                        <ButtonMoreInfo
                          iconOnly
                          iconButtonColor="warning"
                          sxIcon={{ fontSize: "1rem", mt: 0.3, opacity: 0.4 }}
                          label='Repull for "Deep" Error Checking'
                        />
                      )}
                  </Stack>
                  <Stack direction="row" gap={0.5}>
                    {errors.map((error) => (
                      <TypographyError
                        key={`${row.original._id}-${error}-${nanoid()}`}
                      >
                        {error}
                      </TypographyError>
                    ))}
                  </Stack>
                </Stack>
              );
            }
            return (
              <Stack>
                <Stack direction="row" gap={0.5}>
                  {linkComponent}
                  {row.original.docType == "JIB" &&
                    row.original.errors === undefined && (
                      <ButtonMoreInfo
                        iconOnly
                        iconButtonColor="warning"
                        sxIcon={{ fontSize: "1rem", mt: 0.3, opacity: 0.4 }}
                        label='Repull for "Deep" Error Checking'
                      />
                    )}
                </Stack>
              </Stack>
            );
          } else {
            return (
              row.original.amtOriginal || (
                <Typography
                  variant="body2"
                  sx={{ fontStyle: "italic", opacity: 0.6 }}
                >
                  Pull to get value...
                </Typography>
              )
            );
          }
        },
      },

      ...customColumnsDb,
      // ...customColumns
    ],
    [docsOriginal, isFetchingLocally, customColumnsDb]
  );

  let globalButtons = (
    <>
      <Stack direction="row" alignItems="center" gap={3} sx={{ ml: 1 }}>
        {/* <Typography>
          Total: {docsOriginal.length}
        </Typography> */}

        <ButtonStyled
          variant={"text"}
          onClick={() => setOpenModalCustomColumn(true)}
        >
          Add Custom Column
        </ButtonStyled>

        {/* DATE RANGE COMPONENT */}
        {/* <Stack direction="row" gap={1}>
          <DateRangeFilter 
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}

            // docsOriginal={docsOriginal}
            // setDocs={setDocsOriginal}
          />

          <Tooltip title={selectedColumnToFilterDateRange} placement="top">
            <IconButton
              onClick={() => setOpenModalSelectColumn(!openModalSelectColumn)}
              color="primary"
            >
              <CalendarTodayRounded />
            </IconButton>
          </Tooltip>
        </Stack> */}

        {/* <ButtonStyled
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            //only export selected rows
            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export Selected Rows
        </Button> */}
      </Stack>
    </>
  );

  function Filter({ column, table }) {
    const firstValue = table
      .getPreFilteredRowModel()
      .flatRows[0]?.getValue(column.id);
    // console.log("firstValue", firstValue)

    const columnFilterValue = column.getFilterValue();
    // console.log("columnFilterValue", columnFilterValue)

    // console.log("column.getFacetedUniqueValues()", column.getFacetedUniqueValues())
    // console.log("column.getFacetedUniqueValues().keys", column.getFacetedUniqueValues().keys())

    const sortedUniqueValues = useMemo(
      () =>
        typeof firstValue === "number"
          ? []
          : Array.from(column.getFacetedUniqueValues().keys())
              .sort()
              .map((value) => ({ title: value, value: value })),
      [column.getFacetedUniqueValues()]
    );
    // console.log("sortedUniqueValues", sortedUniqueValues)

    return typeof firstValue === "number" ? (
      <>
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={columnFilterValue?.[0] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old) => [value, old?.[1]])
          }
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0]
              ? column.getFacetedMinMaxValues()?.[0]
              : ""
          }`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={columnFilterValue?.[1] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old) => [old?.[0], value])
          }
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1]
              ? column.getFacetedMinMaxValues()?.[1]
              : ""
          }`}
          className="w-24 border shadow rounded"
        />
      </>
    ) : (
      <>
        {/* {sortedUniqueValues.slice(0, 5000).map((value) => (
            <option value={value} key={value} />
          ))} */}
        <Autocomplete // https://stackoverflow.com/questions/70363322/how-customize-material-ui-select
          id="free-solo-demo"
          freeSolo
          autoComplete
          autoHighlight
          size="small"
          sx={{
            pt: "3px",
          }}
          onChange={(event, value) => {
            column.setFilterValue(value);
          }}
          // options={sortedUniqueValues}
          options={sortedUniqueValues.map((option) => option.title)}
          renderOption={(props, option) => (
            <ListItem {...props} sx={{ fontSize: "0.6rem" }}>
              {option}
            </ListItem>
          )}
          renderInput={(params) => (
            <DebouncedInput
              params={params}
              type="text"
              value={columnFilterValue ?? ""}
              onChange={(value) => {
                column.setFilterValue(value);
              }}
              placeholder={`Filter by operator... (${
                column.getFacetedUniqueValues().size
              })`}
              // className="w-36 border shadow rounded"
              // list={column.id + 'list'}
            />

            // <TextField
            //   {...params}
            //   label="select or type"

            //   onChange={value => column.setFilterValue(value)}
            //   placeholder={`Seach... ${column.getFacetedUniqueValues().size}`}
            // />
          )}
        />
        {/* <datalist id={column.id + 'list'}>
            {sortedUniqueValues.slice(0, 5000).map((value) => (
              <option value={value} key={value} />
            ))}

            <TextField 
              onChange={value => column.setFilterValue(value)}
              placeholder={`Seach... ${column.getFacetedUniqueValues().size}`}
            />

            <DebouncedInput
              type="text"
              value={(columnFilterValue ?? '')}
              onChange={value => column.setFilterValue(value)}
              placeholder={`Seach... ${column.getFacetedUniqueValues().size}`}
              className="w-36 border shadow rounded"
              list={column.id + 'list'}
            />
          </datalist> */}
      </>
    );
  }

  function VerticalRangeFilter({ column: { filterValue, setFilter } }) {
    const handleChange = (event, which) => {
      const newFilterValue = {
        ...filterValue,
        [which]: event.target.value,
      };
      setFilter(newFilterValue);
    };

    return (
      <Box display="flex" flexDirection="column">
        <TextField
          label="Min"
          value={filterValue?.min || ""}
          onChange={(event) => handleChange(event, "min")}
          type="number"
        />
        <TextField
          label="Max"
          value={filterValue?.max || ""}
          onChange={(event) => handleChange(event, "max")}
          type="number"
        />
      </Box>
    );
  }

  // A debounced input react component
  function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    params,
    ...props
  }) {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value);
      }, debounce);

      return () => clearTimeout(timeout);
    }, [value]);

    return (
      <TextField
        {...params}
        {...props}
        size="small"
        // margin="dense"
        margin="none"
        variant="standard"
        value={value}
        onChange={(e) => setValue(e.target.value)}

        // sx={(theme) => ({
        //   p: 0,
        //   // minWidth: isRangeFilter
        //   //   ? '100px'
        //   //   : !filterChipLabel
        //   //   ? '120px'
        //   //   : 'auto',
        //   // width: 'calc(100% + 4px)',
        //   // mx: '-2px',
        //   // '& .MuiSelect-icon': {
        //   //   mr: '1.5rem',
        //   // },
        // })}

        // SelectProps={{
        //   displayEmpty: true,
        //   multiple: isMultiSelectFilter,
        //   renderValue: isMultiSelectFilter
        //     ? (selected: any) =>
        //         !selected?.length ? (
        //           <Box sx={{ opacity: 0.5 }}>{filterPlaceholder}</Box>
        //         ) : (
        //           <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '2px' }}>
        //             {(selected as string[])?.map((value) => {
        //               const selectedValue = columnDef.filterSelectOptions?.find(
        //                 (option) =>
        //                   option instanceof Object
        //                     ? option.value === value
        //                     : option === value,
        //               );
        //               return (
        //                 <Chip
        //                   key={value}
        //                   label={
        //                     selectedValue instanceof Object
        //                       ? selectedValue.text
        //                       : selectedValue
        //                   }
        //                 />
        //               );
        //             })}
        //           </Box>
        //         )
        //     : undefined,
        // }}
      />

      // <MRT_TextField
      //   params={params}
      //   props={props}
      //   value={value}
      //   onChange={e => setValue(e.target.value)}
      // />
    );
  }

  function MRT_TextField({
    // from https://github.com/KevinVandy/material-react-table/blob/main/packages/material-react-table/src/inputs/MRT_FilterTextField.tsx
    params,
    props,
    value,
    onChange,
  }) {
    const filterChipLabel = false;

    // styling
    // const {
    //   options: {
    //     enableColumnFilterModes,
    //     columnFilterModeOptions,
    //     icons: { FilterListIcon, CloseIcon },
    //     localization,
    //     manualFiltering,
    //     muiTableHeadCellFilterTextFieldProps,
    //   },
    // } = table;

    // const mTableHeadCellFilterTextFieldProps =
    //   muiTableHeadCellFilterTextFieldProps instanceof Function
    //     ? muiTableHeadCellFilterTextFieldProps({
    //         column,
    //         table,
    //         rangeFilterIndex,
    //       })
    //     : muiTableHeadCellFilterTextFieldProps;

    // const mcTableHeadCellFilterTextFieldProps =
    //   columnDef.muiTableHeadCellFilterTextFieldProps instanceof Function
    //     ? columnDef.muiTableHeadCellFilterTextFieldProps({
    //         column,
    //         table,
    //         rangeFilterIndex,
    //       })
    //     : columnDef.muiTableHeadCellFilterTextFieldProps;

    // const textFieldProps = {
    //   ...mTableHeadCellFilterTextFieldProps,
    //   ...mcTableHeadCellFilterTextFieldProps,
    // }

    return (
      <>
        <TextField
          // size="small"
          // margin="dense"
          {...params}
          {...props}
          value={value}
          onChange={onChange}
          fullWidth
          // inputProps={{
          //   disabled: !!filterChipLabel,
          //   sx: {
          //     textOverflow: 'ellipsis',
          //     width: filterChipLabel ? 0 : undefined,
          //   },
          //   title: filterPlaceholder,
          // }}
          // helperText={
          //   showChangeModeButton ? (
          //     <label>
          //       {localization.filterMode.replace(
          //         '{filterType}',
          //         // @ts-ignore
          //         localization[
          //           `filter${
          //             currentFilterOption?.charAt(0)?.toUpperCase() +
          //             currentFilterOption?.slice(1)
          //           }`
          //         ],
          //       )}
          //     </label>
          //   ) : null
          // }
          // FormHelperTextProps={{
          //   sx: {
          //     fontSize: '0.75rem',
          //     lineHeight: '0.8rem',
          //     whiteSpace: 'nowrap',
          //   },
          // }}
          margin="none"
          // placeholder={
          //   filterChipLabel || isSelectFilter || isMultiSelectFilter
          //     ? undefined
          //     : filterPlaceholder
          // }
          // onChange={handleChange}
          // onClick={(e: MouseEvent<HTMLInputElement>) => e.stopPropagation()}
          // select={isSelectFilter || isMultiSelectFilter}
          // value={filterValue ?? ''}

          variant="standard"

          // InputProps={{
          //   startAdornment: showChangeModeButton ? (
          //     <InputAdornment position="start">
          //       <Tooltip arrow title={localization.changeFilterMode}>
          //         <span>
          //           <IconButton
          //             aria-label={localization.changeFilterMode}
          //             onClick={handleFilterMenuOpen}
          //             size="small"
          //             sx={{ height: '1.75rem', width: '1.75rem' }}
          //           >
          //             <FilterListIcon />
          //           </IconButton>
          //         </span>
          //       </Tooltip>
          //       {filterChipLabel && (
          //         <Chip
          //           onDelete={handleClearEmptyFilterChip}
          //           label={filterChipLabel}
          //         />
          //       )}
          //     </InputAdornment>
          //   ) : null,
          //   endAdornment: !filterChipLabel && (
          //     <InputAdornment position="end">
          //       <Tooltip
          //         arrow
          //         placement="right"
          //         title={localization.clearFilter ?? ''}
          //       >
          //         <span>
          //           <IconButton
          //             aria-label={localization.clearFilter}
          //             disabled={!filterValue?.toString()?.length}
          //             onClick={handleClear}
          //             size="small"
          //             sx={{
          //               height: '1.75rem',
          //               width: '1.75rem',
          //             }}
          //           >
          //             <CloseIcon />
          //           </IconButton>
          //         </span>
          //       </Tooltip>
          //     </InputAdornment>
          //   ),
          // }}

          // SelectProps={{
          //   displayEmpty: true,
          //   multiple: isMultiSelectFilter,
          //   renderValue: isMultiSelectFilter
          //     ? (selected: any) =>
          //         !selected?.length ? (
          //           <Box sx={{ opacity: 0.5 }}>{filterPlaceholder}</Box>
          //         ) : (
          //           <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '2px' }}>
          //             {(selected as string[])?.map((value) => {
          //               const selectedValue = columnDef.filterSelectOptions?.find(
          //                 (option) =>
          //                   option instanceof Object
          //                     ? option.value === value
          //                     : option === value,
          //               );
          //               return (
          //                 <Chip
          //                   key={value}
          //                   label={
          //                     selectedValue instanceof Object
          //                       ? selectedValue.text
          //                       : selectedValue
          //                   }
          //                 />
          //               );
          //             })}
          //           </Box>
          //         )
          //     : undefined,
          // }}
          // {...textFieldProps}
          // inputRef={(inputRef) => {
          //   filterInputRefs.current[`${column.id}-${rangeFilterIndex ?? 0}`] =
          //     inputRef;
          //   if (textFieldProps.inputRef) {
          //     textFieldProps.inputRef = inputRef;
          //   }
          // }}
          // sx={(theme) => ({
          //   p: 0,
          //   minWidth: isRangeFilter
          //     ? '100px'
          //     : !filterChipLabel
          //     ? '120px'
          //     : 'auto',
          //   width: 'calc(100% + 4px)',
          //   mx: '-2px',
          //   '& .MuiSelect-icon': {
          //     mr: '1.5rem',
          //   },
          //   ...(textFieldProps?.sx instanceof Function
          //     ? textFieldProps.sx(theme)
          //     : (textFieldProps?.sx)),
          // })}
        >
          {/* {(isSelectFilter || isMultiSelectFilter) && (
            <MenuItem divider disabled hidden value="">
              <Box sx={{ opacity: 0.5 }}>{filterPlaceholder}</Box>
            </MenuItem>
          )}
          {textFieldProps.children ??
            columnDef?.filterSelectOptions?.map(
              (option: string | { text: string; value: string }) => {
                let value: string;
                let text: string;
                if (typeof option !== 'object') {
                  value = option;
                  text = option;
                } else {
                  value = option.value;
                  text = option.text;
                }
                return (
                  <MenuItem
                    key={value}
                    sx={{
                      display: 'flex',
                      m: 0,
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                    value={value}
                  >
                    {isMultiSelectFilter && (
                      <Checkbox
                        checked={(
                          (column.getFilterValue() ?? []) as string[]
                        ).includes(value)}
                        sx={{ mr: '0.5rem' }}
                      />
                    )}
                    {text}
                  </MenuItem>
                );
              },
            )} */}
        </TextField>
        {/* <MRT_FilterOptionMenu
          anchorEl={anchorEl}
          header={header}
          setAnchorEl={setAnchorEl}
          table={table}
          setFilterValue={setFilterValue}
        /> */}
      </>
    );
  }

  return (
    <>
      <Pod
        elevation={0}
        sx={{
          minWidth: 320,
          minHeight: 100,
          maxHeight: 600,
          width: 1,
          borderRadius: 0,
          ...sx,
        }}
      >
        {/* <MaterialReactTable
          data={docsFinal}
          columns={columns}
          getFacetedUniqueValues={getFacetedUniqueValues()}
        /> */}

        <MRT
          tableType="invoices"
          data={docsFinal}
          columns={columns}
          dataOriginal={docsOriginal}
          setDataOriginal={setDocsOriginal} // modifiy original data (then updates parent component appropriately > gets run through modifications in this component again)
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          // estimatedNumOfRows={5}

          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          globalButtons={globalButtons}
          enableTopToolbar={enableTableHeader ? true : false}
          enableColumnResizing={enableTableHeader ? true : false}
          // enableColumnResizing={false}
          enableTableHead={enableTableHeader ? true : false}
          enableGlobalFilter={enableTableHeader ? true : false}
          enableFilters={enableTableHeader ? true : false}
          enableHiding={enableTableHeader ? true : false}
          enableDensityToggle={enableTableHeader ? true : false}
          enableFullScreenToggle={enableTableHeader ? true : false}
          enableToolbarInternalActions={enableTableHeader ? true : false}
          // additional
          isFetchingLocally={isFetchingLocally}
          setIsFetchingLocally={setIsFetchingLocally}
          enableRowNumbers={enableTableHeader ? true : false}
          enableRowSelection={true}
          enableBottomToolbar={true}
          enablePagination={true}
          enableColumnFilterModes={true}
        />
      </Pod>
      {/* // renderTopToolbarCustomActions= {(table) => {
          //   return (
          //     <ButtonStyled
          //       disabled={
          //         !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          //       }
          //       //only export selected rows
          //       onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          //       startIcon={<FileDownloadIcon />}
          //       variant="contained"
          //     >
          //       Export Selected Rows
          //     </ButtonStyled>
          //   )
          // }}
          // enableRowSelection={ (row) => {
          //     const statements = row.original.statements || []
          //     const pulled = statements.length > 0
          //     return pulled // only be able to select row if "pulled"/statements (aka select for "printing"/exporting data as CSV)
          //   }
          // }
          // enableRowNumbers={true} */}

      <LoadingModal open={isFetchingLocally} setOpen={setIsFetchingLocally} />
      <ModalInvoicesCustomColumn
        open={openModalCustomColumn}
        setOpen={setOpenModalCustomColumn}
        customColumns={customColumns}
        setCustomColumns={setCustomColumns}
      />
      <SelectedColumnForDateRangeFilter
        open={openModalSelectColumn}
        setOpen={setOpenModalSelectColumn}
        selectedColumn={selectedColumnToFilterDateRange}
        setSelectedColumn={setSelectedColumnToFilterDateRange}
      />
    </>
  );
}

// COMPONENTS
function DateRangeFilter({ fromDate, setFromDate, toDate, setToDate }) {
  // useEffect(() => {
  //   if (fromDate || toDate) {
  //     console.log("got here 00")
  //     const isValidFromDate = isValidDate(new Date(fromDate))
  //     const isValidToDate = isValidDate(new Date(toDate))
  //     console.log("got here 0")
  //     if (isValidFromDate || isValidToDate) {
  //       console.log("got here")
  //       console.log("docsLength", docsOriginal.length)
  //       const filteredDocs = filterDocsByDateRange(docsOriginal, key, fromDate, toDate, isValidFromDate, isValidToDate)
  //       console.log("docsLength", filteredDocs.length)
  //       setDocs(filteredDocs)
  //     }
  //     else {
  //       console.log("got here 2")
  //       setDocs(docsOriginal)
  //     }
  //   }
  // }, [])

  return (
    <>
      <Stack direction="row" gap={1}>
        <TextField
          size="small"
          // type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          InputProps={{
            placeholder: "From (m/d/yyyy)",
            endAdornment: fromDate && (
              <InputAdornment
                position="end"
                sx={{ mr: "-8px" }}
                onClick={() => setFromDate("")}
              >
                <IconButton size="small" tabIndex={-1}>
                  <ClearRounded sx={{ opacity: 0.4 }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          size="small"
          // type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          InputProps={{
            placeholder: "To (m/d/yyyy)",
            endAdornment: toDate && (
              <InputAdornment
                position="end"
                sx={{ mr: "-8px", tabIndex: -1 }}
                onClick={() => setToDate("")}
              >
                <IconButton size="small" tabIndex={-1}>
                  <ClearRounded sx={{ opacity: 0.4 }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </>
  );
}

function SelectedColumnForDateRangeFilter({
  open,
  setOpen,
  selectedColumn,
  setSelectedColumn,
}) {
  const options = ["cashDate", "docDate", "receivedDate"];

  const handleChange = (event) => {
    setSelectedColumn(event.target.value);
  };

  return (
    <ModalGeneric
      open={open}
      setOpen={setOpen}
      showCancel={false}
      showSave={false}
    >
      <TextField
        // fullWidth
        // label="County"
        select
        size="small"
        value={selectedColumn}
        onChange={handleChange}
        // inputProps={{}}
        // InputProps={{sx: {fontSize: "1.2rem"}}}

        // sx={{
        //   width: 0.55,
        //   '& legend': { display: 'none' },
        //   '& fieldset': { top: 0 },
        // }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </ModalGeneric>
  );
}
