import axios from "axios";
import { SCRAPETYPE } from "./constants";
import { handleErrorCatching } from "./handleErrorCatching";
import { isFalsyFromLocalStorage } from "./isFalsyFromLocalStorage";

export function arrayToCsv(data){
  return data.map(row =>
    row
    .map(String)  // convert every value to String
    .map(v => v.replaceAll('"', '""'))  // escape double colons
    .map(v => `"${v}"`)  // quote it
    .join(',')  // comma-separated
  ).join('\r\n');  // rows starting on new lines
}



// let csv = 
// "1","2","""3"""
// "true","null","undefined"


/** Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export function downloadBlob(content, filename, contentType) {
  // Create a blob
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
}


export function handleExportRows(rows=[]) {
  console.log("EXPORT ROWS", rows)
  // const invoicesAndStatements = []
  // rows.forEach(row => {
  //   const ELid = row.original.clientELid
  //   const invoiceId = row.original.invoiceId
  //   const foundIndex = invoicesAndStatements.findIndex(obj => obj.ELid == ELid)
  //   if (foundIndex > -1) {
  //     invoicesAndStatements[foundIndex].invoiceIds.push(invoiceId)
  //   }
  //   else {
  //     invoicesAndStatements.push({ELid: ELid, invoiceIds: [invoiceId]})
  //   }
  // })
  // console.log(invoicesAndStatements)
}

export function handlePullRows(table={}, setDocsOriginal=()=>{}, setIsFetching=()=>{}, appDispatch=()=>{}) {
  const rows = table.getSelectedRowModel().rows  // sending whole table, because need to table.resetRowSelection() after pulled
  const reqClientIds = []
  const reqClientsAndInvoices = []

  // NEW VERSION (get requests to Invoice/Statement page via invoiceUrl) (NOPE -- doesn't work with scraping script)
  // rows.forEach(row => {
  //   const ELid = row.original.clientELid
  //   const invoiceUrl = row.original.invoiceUrl
  //   const foundIndex = reqClientsAndInvoices.findIndex(obj => obj.ELid == ELid)
  //   if (foundIndex > -1) {
  //     reqClientsAndInvoices[foundIndex].invoiceUrls.push(invoiceUrl)
  //   }
  //   else {
  //     reqClientIds.push(ELid)
  //     reqClientsAndInvoices.push({ELid: ELid, invoiceUrls: [invoiceUrl]})
  //   }
  // })


  // OLD VERSION (invoiceIds, searching page after page for each row's scraped invoiceObj['invoiceId'] == invoiceId)

  rows.forEach(row => {
    const ELid = row.original.clientELid
    const invoiceId = row.original.invoiceId
    const foundIndex = reqClientsAndInvoices.findIndex(obj => obj.ELid == ELid)
    if (foundIndex > -1) {
      reqClientsAndInvoices[foundIndex].invoiceIds.push(invoiceId)
    }
    else {
      reqClientIds.push(ELid)
      reqClientsAndInvoices.push({ELid: ELid, invoiceIds: [invoiceId]})
    }
  })

  console.log("reqClientsIds", reqClientIds)
  console.log("reqClientsAndInvoices", reqClientsAndInvoices)
  // return { reqClientIds, reqClientsAndInvoices }

  // pullInvoices(reqClientIds, reqClientsAndInvoices, SCRAPETYPE.getInvoicesAndStatementsOfClientsWithInvoiceUrls, setDocsOriginal, setIsFetching, appDispatch)   // NEW VERSION (NOPE -- doesn't work with scraping)
  pullInvoices(reqClientIds, reqClientsAndInvoices, SCRAPETYPE.getInvoicesAndStatementsOfClients, setDocsOriginal, setIsFetching, appDispatch, table)  // OLD VERSION
}


export function pullInvoices(reqClientIds=[], reqClientsAndInvoices=[], scrapeType=SCRAPETYPE.getInvoicesAndStatementsOfClients, setDocsOriginal=()=>{}, setIsFetching=()=>{}, appDispatch=()=>{}, table={}) {
  if (reqClientIds.length > 0 || reqClientsAndInvoices.length > 0) {
    const controller = new AbortController()
    async function getDocs() {
      try {
        const payload = {
          // username: isFalsyFromLocalStorage(appState.user?.requests?.EL?.username) ? "CCG-EL" : appState.user?.requests?.EL?.username,
          // password: isFalsyFromLocalStorage(appState.user?.requests?.EL?.password) ? "Ueg1tsDijVN4" : appState.user?.requests?.EL?.password,
          // userId: isFalsyFromLocalStorage(appState.user?.requests?.EL?.userId) ? "6092864" : appState.user?.requests?.EL?.userId,
          username: "CCG-EL",
          password: "Ueg1tsDijVN4",
          userId: "6092864",
          scrapeType: scrapeType,
          reqClientIds: reqClientIds,
          reqClientsAndInvoices: reqClientsAndInvoices  // [ { ELid: "", invoiceIds: ["172385", "12798539"] } ] 
        }
        console.log("payload (invoices&statements)", payload)
        const response = await axios.post(`/python/invoices`, {payload: payload}, {signal: controller.signal}) // TODO: getDocs or getDocsById  // const docs = await getDocsById("clients", "ELid", id, controller, true)
        // const response = await axios.post(`/python/invoices`, {payload: payload, token: appState.user.token}, {signal: controller.signal}) // TODO: getDocs or getDocsById  // const docs = await getDocsById("clients", "ELid", id, controller, true)
        // const response = await axios.post(`/python/invoices?testing=true`, {payload: payload, token: appState.user.token}, {signal: controller.signal}) // TODO: getDocs or getDocsById  // const docs = await getDocsById("clients", "ELid", id, controller, true)
        console.log(`retrievededDocs (scrapeInvoices): `, response.data)

        // update state in some way
        if (response.data.success) {
          const updatedDocs = response.data.docs
          const invoiceIds = []
          reqClientsAndInvoices.forEach(obj => obj.invoiceIds.forEach(invoiceId => invoiceIds.push(invoiceId)))
          function getUpdatedDoc(invoiceId) {
            return updatedDocs.find(doc => doc.invoiceId == invoiceId)
          }
          setDocsOriginal(docsOriginal => docsOriginal.map(doc => invoiceIds.includes(doc.invoiceId) ? getUpdatedDoc(doc.invoiceId) : doc))

          // toasts
          const scrapedDocsLength = response.data.docs.length
          if (scrapedDocsLength > 0) {
            appDispatch({type: "flashMessage", value: {message: `Successfully pulled ${scrapedDocsLength} invoice${scrapedDocsLength > 1 ? "s" : ""}.`, severity: "success"} })
            table.resetRowSelection()
          }
          else {
            appDispatch({type: "flashMessage", value: {message: `Error pulling invoice(s).`, severity: "error"} })
          }
          
        }

      } catch(e) {
        handleErrorCatching(e, "retrievedDocs, scrapeInvoices")
      } finally {
        setIsFetching(false)
        // setDoc({})
      }
    }
    getDocs()
    return () => { controller.abort() }
  }
}