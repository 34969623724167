import React, { useContext, useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Stack,
  AppBar,
  Toolbar,
  Button,
  ListItemIcon,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import { StateContext } from "../../state-management/app/useContext/StateContext";
import { DispatchContext } from "../../state-management/app/useContext/DispatchContext";
import { Logout, WbSunnyRounded } from "@mui/icons-material";
import { LinkWrapped } from "../utils/LinkWrapped";
import { LogoText } from "../utils/LogoText";
import { Logo } from "../utils/Logo";
import { nanoid } from "nanoid";

export default function SiteTopNav({}) {
  const navigate = useNavigate();
  const location = useLocation();

  const [url, setUrl] = useState(location.pathname);
  const [appBarHeight, setAppBarHeight] = useState(0);

  const refAppBar = useRef(null);

  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  const buttonOpacity = 0.8;
  const buttonsMain = [
    {
      visible: true,
      label: "home",
      href: "/",
      icon: (
        <Stack direction="row" alignItems="center" gap={1} sx={{ mr: 2 }}>
          <Logo whiteVersion width={32} sx={{ opacity: buttonOpacity }} />
          <LogoText whiteVersion width={96} sx={{ opacity: buttonOpacity }} />
        </Stack>
      ),
    },
    // {
    //   visible: appState.loggedIn,
    //   label: "profile",
    //   href: "/profile",
    // },
    // {
    //   visible: true,
    //   label: "users",
    //   href: "/users",
    // },
    // {
    //   visible: true,
    //   label: "clients",
    //   href: "/clients",
    // },

    {
      visible: !appState.loggedIn,
      label: "sign in",
      href: "/signin",
    },
    {
      visible: !appState.loggedIn,
      label: "register",
      href: "/register",
    },
    {
      visible: true,
      label: "theme",
      onClick: () => appDispatch({ type: "theme" }),
      icon: <WbSunnyRounded fontSize="small" sx={{ opacity: buttonOpacity }} />,
      tooltip: "Toggle Light/Dark Mode",
    },

    {
      visible: appState.loggedIn,
      label: "logout",
      onClick: () => appDispatch({ type: "logout" }),
      icon: <Logout fontSize="small" sx={{ opacity: buttonOpacity }} />,
      tooltip: "Logout",
    },
  ];

  useEffect(() => {
    setAppBarHeight(refAppBar.current.clientHeight);
  }, [refAppBar?.current?.clientHeight]);

  return (
    <>
      <AppBar
        ref={refAppBar}
        position="fixed"
        elevation={0}
        sx={{
          // backgroundColor: "transparent",
          backgroundColor: "primary.main",
          color: "#fff",
          // paddingTop: {xs: 1, sm: 1.5, md: 4},
          // paddingBottom: {xs: 1, sm: 1.5, md: 2},
          width: {
            // md: `calc(100% - ${width}px)`
          },

          "& .MuiToolbar-root": {
            paddingLeft: 0,
            paddingRight: 0,
          },
          "& .MuiButton-root": {
            borderRadius: "400px",
          },
          "& .MuiButton-text": {
            "&:hover": {
              opacity: 1,
              filter: "opacity(100%)",
              // fontWeight: 600,
              background: "none",
            },
          },
          "& .MuiButton-contained": {
            opacity: 1,
            // boxShadow: "4px 4px 12px 0px rgba(0,0,0,0.2)",
            boxShadow: "4px 4px 12px 0px rgba(0, 123, 255, 0.2)",
          },
        }}
      >
        <Toolbar variant="dense" sx={{ minHeight: { xs: 7, sm: 7 } }}>
          <Stack direction="row" gap={2} display={"block"} sx={{ width: 1 }}>
            {buttonsMain
              .filter((btn) => btn.visible)
              .map(({ label, href, onClick, icon, tooltip }, i) =>
                icon ? (
                  <Tooltip
                    key={`key=${nanoid()}-tooltip-${href}`}
                    title={tooltip}
                  >
                    <IconButton
                      disableRipple={label == "home" ? true : false}
                      key={`key=${href}-${label}`}
                      size="large"
                      onClick={
                        onClick
                          ? onClick
                          : () => {
                              setUrl(href);
                              navigate(href);
                            }
                      }
                      sx={{
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: label == "home" && "transparent",
                        },
                      }}
                    >
                      {icon}
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Button
                    key={`key=${nanoid()}-button-${href}`}
                    size="large"
                    onClick={
                      onClick
                        ? onClick
                        : () => {
                            setUrl(href);
                            navigate(href);
                          }
                    }
                    sx={{
                      opacity: url == href ? 1 : buttonOpacity,
                      color: "#ffffff",
                      // fontWeight: (url == bookmark) && 600,
                    }}
                  >
                    {label}
                  </Button>
                )

                // <LinkWrapped
                //   key={`key-${href}`}
                //   to={href}
                //   sx={{color: "#fff"}}
                // >
                //   {label}
                // </LinkWrapped>
              )}
          </Stack>
        </Toolbar>
      </AppBar>

      {/* necessary for spacing when AppBar position='fixed' -- https://mui.com/#fixed-placement */}
      <Toolbar variant="dense" sx={{ minHeight: appBarHeight }} />
    </>
  );
}
